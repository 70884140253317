import { FC, ReactNode, useContext, useMemo, useRef } from 'react';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { KeycloakContext } from 'components/Secured';
import IProduct from 'models/product';

interface LogisticQuestionsWrapperProps {
  state: IProduct;
  children: ReactNode;
}

const LogisticQuestionsWrapper: FC<LogisticQuestionsWrapperProps> = ({ state, children }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');

  const keepRef = useRef({ state });

  const shouldExpandDefault = useMemo(() => {
    const refState = keepRef.current.state;
    return (
      // Vilken/Vilka artikelnivå(er) är beställningsbara?
      refState.consumerPackOrderableUnit ||
      refState.storePackOrderableUnit ||
      refState.transportOrderableUnit ||
      refState.palletOrderableUnit ||
      // Vilken/Vilka artikelnivå(er) används för transport?
      refState.consumerPackDespatchUnit ||
      refState.storePackDespatchUnit ||
      refState.transportDespatchUnit ||
      refState.palletDespatchUnit ||
      // Lanseringsdag mot konsument för nyheter
      refState.basicDataAvailabilityDateFrom ||
      // Första beställningsdag för nyheter
      refState.articleFirstOrderDate ||
      // Första leveransdag för nyheter
      refState.articleFirstDelivery
    );
  }, []);

  if (!isProducer)
    return (
      <>
        <h3>Logistisk information för återförsäljare</h3>
        {children}
      </>
    );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={shouldExpandDefault ? ['root'] : undefined}
      style={{ margin: '20px 0' }}
    >
      <TreeItem nodeId="root" label={<h3 style={{ margin: 0 }}>Logistisk information för återförsäljare</h3>}>
        {children}
      </TreeItem>
    </TreeView>
  );
};

export default LogisticQuestionsWrapper;
