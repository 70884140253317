import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from "components/inputfields/InputLabel";
import { IFoodlaCategoryOption } from "models/category";
import IProduct from "models/product";
import { isChemical, isCosmetic, isDetergent, isElectric, isGoods, isJewelry, isMedical, isSunscreen, isToy } from "./category";

interface IReachSubstanceProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory?: IFoodlaCategoryOption;
};

const ReachSubstance = ({ state, setState, rootCategory }: IReachSubstanceProps) => {
  const isReachSubstanceRequired = (isToy(state?.foodlaCategory, rootCategory)
  || isElectric(state?.foodlaCategory, rootCategory)
  || isJewelry(state?.foodlaCategory, rootCategory)
  || isChemical(state?.foodlaCategory, rootCategory)
  || isDetergent(state?.foodlaCategory, rootCategory)
  || isCosmetic(state?.foodlaCategory, rootCategory)
  || isSunscreen(state?.foodlaCategory, rootCategory));

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };
  return (
    <>
      <FormControl component="fieldset">
        <InputLabel 
          heading="Innehåller produkten ett ämne som är förbjudet eller begränsat i REACH-förordningen?"
          text="Du som är tillverkare, importör eller nedströmsanvändare måste ha tillstånd om du vill använda eller släppa ut ett tillståndspliktigt ämne på marknaden. Det gäller både rena ämnen och ämnen som ingår i en blandning."
          link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/reach-forordningen/tillstand-i-reach-for-sarskilt-farliga-amnen"
          labelStyles={{margin: 0}}
          required={isReachSubstanceRequired}
        />
        <RadioGroup
          value={state?.reachSubstance}
          name="reachSubstance"
          onChange={handleRadioChange}
          style={{ flexDirection: "row", gridGap: 16 }}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default ReachSubstance;