import React, { useContext } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';

import InputLabel from '../../../inputfields/InputLabel';
import FloatInput from '../FloatInput';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import IProduct from '../../../../models/product';
import { KeycloakContext } from 'components/Secured';
import useViewport from 'hooks/useViewports';
import { isBlank } from 'utils/helpers-ts';

interface INutritionProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
  classes?: any;
}

const styles = (theme: any) => ({
  asterisk: {
    color: theme.palette.error.main,
  },
});

export const isEnergyError = (state?: IProduct) => {
  if (isBlank(state?.energi_kj) || isBlank(state?.energi_kcal)) return false;
  // kJ must be: 4 kcal <= kJ <= 4.5 kcal
  // Check that value for energi_kj is between 4 and 4.5 times the value for energi_kcal
  const kJ = Number(state?.energi_kj);
  const kCal = Number(state?.energi_kcal);
  if (4 * kCal <= kJ && kJ <= 4.5 * kCal) return false;
  return true;
}

export const CheckEnergyError = ({ state }: { state?: IProduct }) => {
  if (!isEnergyError(state)) return null;

  return (
    <div style={{ fontWeight: 'bold', color: 'orangered', marginBottom: 8 }}>
      <span>Det verkar som om att du angivit värden för kJ och kcal som inte stämmer överens. Stäm gärna av så att värdena stämmer (1 kcal = 4,184 kJ) eller hör av dig till{` `}</span>
      <Link underline="always" target="_blank" href="mailto:support@foodla.nu">
        support@foodla.nu
      </Link>
      <span>{` `}så hjälper vi till!</span>
    </div>
  );
}

export const Nutrition = ({ state, setState, classes, approve }: INutritionProps) => {
  const { smUp, mdUp } = useViewport();

  // TODO any
  const handleChange = (event: any) => {
    const stateName = event.target.name;
    let stateValue = event.target.value;

    setState({ ...state, [stateName]: stateValue });
  };

  const labelStyle: React.CSSProperties = { flex: mdUp ? '0 0 200px' : '0 0 175px' };
  const inputStyle: React.CSSProperties = { flex: mdUp ? '0 0 150px' : 1 };

  const renderDRI = (value: number, dri: number, grow?: boolean) => {
    if (!value) return null;

    return (
      <div
        style={{
          fontSize: 14,
          fontWeight: 400,
          alignSelf: 'center',
          width: grow && smUp ? 'calc(100% - 420px)' : '',
          marginLeft: 'auto',
          marginBottom: grow ? 0 : 8,
        }}
      >
        &nbsp;{value ? `(${Math.round((value / dri) * 100)}% av DRI)` : ''}
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', flex: 1 }}>
        <h3 style={{ ...(mdUp ? labelStyle : {}), marginTop: 9, marginBottom: mdUp ? 9 : 0 }}>
          Näringsvärde per 100
        </h3>

        <FormControl component="fieldset">
          <RadioGroup row aria-label="nutritional_unit" value={state.nutritional_unit || "GRAM"} name="nutritional_unit" onChange={handleChange}>
              <FormControlLabel
                value="GRAM"
                control={<Radio color="primary" />}
                label="gram"
                style={{ marginLeft: 0 }}
              />
              <FormControlLabel
                value="MILLILITER"
                control={<Radio color="primary" />}
                label="milliliter"
              />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', flex: 1 }}>
        <h3 style={mdUp ? labelStyle : { flex: 0, marginBottom: 0 }}>
          Energi<span className={classes.asterisk}>*</span>:
        </h3>
        <div style={{ flex: 1, display: 'flex', gap: 4 }}>
          <div>
            <FloatInput
              name="energi_kj"
              // requiredNutritional={true}
              required={true}
              state={state}
              setState={setState}
              style={inputStyle}
              forceRed={isEnergyError(state)}
            />
            {renderDRI(state.energi_kj || 0, 8400)}
          </div>
          <h3 style={{ whiteSpace: 'nowrap' }}>&nbsp;kJ /&nbsp;</h3>

          <div>
            <FloatInput
              name="energi_kcal"
              required={true}
              // requiredNutritional={true}
              state={state}
              setState={setState}
              style={inputStyle}
              forceRed={isEnergyError(state)}
            />
            {renderDRI(state.energi_kcal || 0, 2000)}
          </div>
          <h3>&nbsp;kcal</h3>
        </div>
      </div>
      <CheckEnergyError state={state} />
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Fett<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="fett"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.fett || 0, 70, true)}
      </div>
      <h3 style={{marginBottom: 2, marginTop: 2}}>
        &nbsp;&nbsp;&nbsp;&nbsp;varav:
      </h3>

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- mättat fett<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="mattatFett"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.mattatFett || 0, 20, true)}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- enkelomättat fett:
        </h3>
        <FloatInput
          name="enkelomattatFett"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- fleromättat fett:
        </h3>
        <FloatInput
          name="flerromattatFett"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
        <Divider style={{flex: 0.9}} />
        {approve && state?.approve?.find((x) => x.field === 'nutrition') && (
          <ThreeWaySwitch
            style={{ flex: 0.1, marginBottom: 8 }}
            name="nutrition"
            state={state}
            setState={setState}
          />
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
        Kolhydrat<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="kolhydrat"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.kolhydrat || 0, 260, true)}
      </div>
      <h3 style={{marginBottom: 2, marginTop: 2}}>
        &nbsp;&nbsp;&nbsp;&nbsp;varav:
      </h3>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- sockerarter<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="sockerarter"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.sockerarter || 0, 90, true)}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- polyoler:
        </h3>
        <FloatInput
          name="polyoler"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          &nbsp;&nbsp;&nbsp;&nbsp;- stärkelse:
        </h3>
        <FloatInput
          name="starkelse"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Fiber:
        </h3>
        <FloatInput
          name="fiber"
          required={false}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Protein<span className={classes.asterisk}>*</span>:
        </h3>

        <FloatInput
          name="protein"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.protein || 0, 50, true)}
      </div>
      <Divider />

      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <h3 style={labelStyle}>
          Salt<span className={classes.asterisk}>*</span>:
        </h3>
        <FloatInput
          name="salt"
          required={true}
          // requiredNutritional={true}
          state={state}
          setState={setState}
          style={inputStyle}
        />
        <h3>&nbsp;g</h3>
        {renderDRI(state.salt || 0, 6, true)}
      </div>
      <Divider />
    </>
  );
};

const NutritionBase = withStyles(styles)(Nutrition);

const validValue = (value?: number) => {
  return !!value || value === 0;
}

const NutritionWrapper = ({ state, ...props }: INutritionProps) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');

  const expand = isStore && (
    validValue(state.energi_kj) ||
    validValue(state.energi_kcal) ||
    validValue(state.fett) ||
    validValue(state.mattatFett) ||
    validValue(state.enkelomattatFett) ||
    validValue(state.flerromattatFett) ||
    validValue(state.kolhydrat) ||
    validValue(state.sockerarter) ||
    validValue(state.polyoler) ||
    validValue(state.starkelse) ||
    validValue(state.fiber) ||
    validValue(state.protein) ||
    validValue(state.salt)
  );

  const labelElement = (
    <InputLabel
      heading="Näringsdeklaration"
      text="Obligatorisk, i de flesta fall, men det finns några undantag. Den obligatoriska näringsdeklarationen ska innehålla uppgifter om energivärde, och mängden av fett, mättat fett, kolhydrat, sockerarter, protein och salt."
      link="http://kontrollwiki.livsmedelsverket.se/artikel/46/naringsdeklaration"
    />
  );

  if (!isStore) {
    return (
      <>
        {labelElement}
        <NutritionBase {...props} state={state} />
      </>
    );
  }

  return (
    <Box my={1}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expand ? ["root"] : undefined}
      >
        <TreeItem nodeId="root" label={labelElement}>
          <NutritionBase {...props} state={state} />
        </TreeItem>
      </TreeView>
    </Box>
  );
}

export default NutritionWrapper;
