import {
  head,
  tail,
  path,
  pathOr,
} from 'ramda';
import capitalize from 'capitalize';

import { isDefined, isNotDefined, toSafeFloat, hasValue, toSafeNumber } from '../../../utils/helpers';
import { isNonFoodProduct, transformCategoryRead, transformCategoryWrite } from 'utils/products';
import {
  SUPERUSER_APPROVAL,
  APPLICATION_ROLES,
  ORGANIZATION_SPECIFIC_FIELDS,
  PRODUCT_NOTE_TYPES_EXTRACTOR,
} from '../../constants';
import { requiresClassification } from '../../constants-ts';
import {
  requiredNutritionalFields,
} from './constants';
import { isEmpty } from 'lodash';
import { isFruktOrGreen, removeUnnecessaryAprroveFields } from './fields/category';
import { getItems } from './fields/search-terms';
import { addAutoEnhanceParam } from 'components/fileuploader/ImageUploader2';

export const capitalizeTitle = (value) => {
  let title = capitalize.words(value, {
    skipWord: (word, count) => {
      if (word.trim() === 'pack' && count > 0) return true;
      return false;
    },
    preserve: true,
  });
  if (title) {
    title = title.replace(/-\s*./gmu, x => x.toLowerCase());
  }
  // const match = title.match(/-\s*(.?)/gmu);
  // if (match) {
  //   alert(match.toLocaleString().toLowerCase());
  // }
  return title;
};

export const getProductMutationInput = (product, state, userRole, imageUrls, isCreatingNew, rootCategory) => {
  const inputFields = {
    title: capitalizeTitle(state.title),
    pricing: state.pricing,
    short_text: state.short_text,
    longitude: state.longitude,
    latitude: state.latitude,
    location: state.location,
    EAN: state.EAN,
    EAN2: state.EAN2,
    BNR: state.BNR,
    video: state.video,
    unit: state.unit,
    foodlaCategory: state.foodlaCategory,
    ingredient_statement: state.ingredient_statement,

    energi_kj: toSafeFloat(state.energi_kj, null),
    energi_kcal: toSafeFloat(state.energi_kcal, null),
    fett: toSafeFloat(state.fett, null),
    mattatFett: toSafeFloat(state.mattatFett, null),
    enkelomattatFett: toSafeFloat(state.enkelomattatFett, null),
    flerromattatFett: toSafeFloat(state.flerromattatFett, null),
    kolhydrat: toSafeFloat(state.kolhydrat, null),
    sockerarter: toSafeFloat(state.sockerarter, null),
    polyoler: toSafeFloat(state.polyoler, null),
    starkelse: toSafeFloat(state.starkelse, null),
    fiber: toSafeFloat(state.fiber, null),
    protein: toSafeFloat(state.protein, null),
    salt: toSafeFloat(state.salt, null),
    nutritional_unit: state.nutritional_unit,
    // vitaminer: state.vitaminer,

    vitaminA: toSafeFloat(state.vitaminA, null),
    vitaminD: toSafeFloat(state.vitaminD, null),
    vitaminE: toSafeFloat(state.vitaminE, null),
    vitaminK: toSafeFloat(state.vitaminK, null),
    vitaminC: toSafeFloat(state.vitaminC, null),
    tiamin: toSafeFloat(state.tiamin, null),
    riboflavin: toSafeFloat(state.riboflavin, null),
    niacin: toSafeFloat(state.niacin, null),
    vitaminB6: toSafeFloat(state.vitaminB6, null),
    folsyra: toSafeFloat(state.folsyra, null),
    vitaminB12: toSafeFloat(state.vitaminB12, null),
    biotin: toSafeFloat(state.biotin, null),
    pantotensyra: toSafeFloat(state.pantotensyra, null),
    kalium: toSafeFloat(state.kalium, null),
    klorid: toSafeFloat(state.klorid, null),
    kalcium: toSafeFloat(state.kalcium, null),
    fosfor: toSafeFloat(state.fosfor, null),
    magnesium: toSafeFloat(state.magnesium, null),
    jarn: toSafeFloat(state.jarn, null),
    zink: toSafeFloat(state.zink, null),
    koppar: toSafeFloat(state.koppar, null),
    mangan: toSafeFloat(state.mangan, null),
    fluorid: toSafeFloat(state.fluorid, null),
    selen: toSafeFloat(state.selen, null),
    krom: toSafeFloat(state.krom, null),
    molybden: toSafeFloat(state.molybden, null),
    jod: toSafeFloat(state.jod, null),

    descriptive_size: state.descriptive_size,
    trade_item_temperature_information: state.trade_item_temperature_information,
    place_of_item_activity: state.place_of_item_activity,
    additional_required_information: state.additional_required_information,
    quantity_in_stock: state.quantity_in_stock,
    adminStatus: userRole === APPLICATION_ROLES.PRODUCER || userRole === APPLICATION_ROLES.STORE ? SUPERUSER_APPROVAL.AWAITING_APPROVAL : state.adminStatus,
    alcoholPercentage: toSafeFloat(state.alcoholPercentage, 0, 1),
    consumer_size_to_order_size_ratio: toSafeFloat(state.consumer_size_to_order_size_ratio, 0),
    approve: removeUnnecessaryAprroveFields(state, rootCategory),
    labels: state.labels,

    color: state.color,
    non_food_ingredients: state.non_food_ingredients,
    material: state.material,
    dimensions: state.dimensions,
    capacity: state.capacity,
    // gross_weight: state.gross_weight,
    // package_size: state.package_size,
    // package_size_unit: state.package_size_unit,
    // country_of_origin: state.country_of_origin,
    country_of_manufacturing: state.country_of_manufacturing,
    brand: state.brand,
    brand_food: state.brand_food,

    min_temperature: toSafeNumber(state?.min_temperature, null),
    max_temperature: toSafeNumber(state?.max_temperature, null),

    gross_weight_num: toSafeFloat(state.gross_weight_num, null),
    gross_height: toSafeFloat(state.gross_height, null),
    gross_width: toSafeFloat(state.gross_width, null),
    gross_depth: toSafeFloat(state.gross_depth, null),
    search_terms: state.search_terms,
    imageUnderProcess: state.imageUnderProcess,
    ignoreEANLengthError: state.ignoreEANLengthError,

    isMagazine: state?.isMagazine,
    price: toSafeFloat(state?.price),
    priceEAN: state?.priceEAN,
    issueActiveDate: toSafeFloat(state?.issueActiveDate),
    lastreturndate: toSafeFloat(state?.lastreturndate),
    totalNumberIssues: toSafeFloat(state?.totalNumberIssues),
    currentIssueNumber: toSafeFloat(state?.currentIssueNumber),
    currentIssueYear: toSafeFloat(state?.currentIssueYear),
    alternativeTitle: state?.alternativeTitle,

    // detailsDescriptiveSizeOfConsumerPackAmount: toSafeFloat(state?.detailsDescriptiveSizeOfConsumerPackAmount),
    // detailsDescriptiveSizeOfConsumerPackUnit: state?.detailsDescriptiveSizeOfConsumerPackUnit,
    // detailsEffectiveDate: toSafeFloat(state?.detailsEffectiveDate),

    // basicDataCategory: toSafeFloat(state?.basicDataCategory),
    basicDataMixCategory: state?.basicDataMixCategory,
    basicDataTradeItemVariableUnit: state?.basicDataTradeItemVariableUnit,
    basicDataTaxClassification: toSafeFloat(state?.basicDataTaxClassification),
    basicDataPriceComparisonContentType: state?.basicDataPriceComparisonContentType,
    basicDataAvailabilityDateFrom: toSafeFloat(state?.basicDataAvailabilityDateFrom, null),
    articleFirstOrderDate: toSafeFloat(state?.articleFirstOrderDate, null),
    articleFirstDelivery: toSafeFloat(state?.articleFirstDelivery, null),
    // basicDataAvailabilityDateTo: toSafeFloat(state?.basicDataAvailabilityDateTo),
    basicDataCbuIndicator: state?.basicDataCbuIndicator,

    // consumerPackQtyOfBaseItem: toSafeFloat(state?.consumerPackQtyOfBaseItem, null),
    consumerPackOrderableUnit: state?.consumerPackOrderableUnit,
    consumerPackDespatchUnit: state?.consumerPackDespatchUnit,
    consumerPackNetWeight: toSafeFloat(state?.consumerPackNetWeight, null),

    // storePackUOMType: state?.storePackUOMType,
    storePackEANForStorePack: state?.storePackEANForStorePack,
    storePackOrderableUnit: state?.storePackOrderableUnit,
    storePackDespatchUnit: state?.storePackDespatchUnit, 
    storePackTradeItemMeasurementsLengthDepth: toSafeFloat(state?.storePackTradeItemMeasurementsLengthDepth, null),
    storePackTradeItemMeasurementsWidth: toSafeFloat(state?.storePackTradeItemMeasurementsWidth, null),
    storePackTradeItemMeasurementsHeight: toSafeFloat(state?.storePackTradeItemMeasurementsHeight, null),
    storePackGrossWeight: toSafeFloat(state?.storePackGrossWeight, null),

    // transportUOMType: state?.transportUOMType,
    transportEANForStorePack: state?.transportEANForStorePack,
    transportQtyOfBaseItem: toSafeFloat(state?.transportQtyOfBaseItem, null),
    transportQtyOfNextLowerItem: toSafeFloat(state?.transportQtyOfNextLowerItem, null),
    transportOrderableUnit: state?.transportOrderableUnit,
    transportDespatchUnit: state?.transportDespatchUnit,
    transportTradeItemMeasurementsLengthDepth: toSafeFloat(state?.transportTradeItemMeasurementsLengthDepth, null),
    transportTradeItemMeasurementsWidth: toSafeFloat(state?.transportTradeItemMeasurementsWidth, null),
    transportTradeItemMeasurementsHeight: toSafeFloat(state?.transportTradeItemMeasurementsHeight, null),
    transportGrossWeight: toSafeFloat(state?.transportGrossWeight, null),
    transportMinTemperature: toSafeFloat(state?.transportMinTemperature, null),
    transportMaxTemperature: toSafeFloat(state?.transportMaxTemperature, null),

    // palletLayerUOMType: state?.palletLayerUOMType,
    palletLayerEANForStorePack: state?.palletLayerEANForStorePack,
    palletLayerQtyOfBaseItem: toSafeFloat(state?.palletLayerQtyOfBaseItem, null),
    palletLayerQtyOfNextLowerItem: toSafeFloat(state?.palletLayerQtyOfNextLowerItem, null),
    palletLayerOrderableUnit: state?.palletLayerOrderableUnit,
    palletLayerDespatchUnit: state?.palletLayerDespatchUnit, 
    palletLayerTradeItemMeasurementsLengthDepth: toSafeFloat(state?.palletLayerTradeItemMeasurementsLengthDepth, null),
    palletLayerTradeItemMeasurementsWidth: toSafeFloat(state?.palletLayerTradeItemMeasurementsWidth, null),
    palletLayerTradeItemMeasurementsHeight: toSafeFloat(state?.palletLayerTradeItemMeasurementsHeight, null),
    palletLayerGrossWeight: toSafeFloat(state?.palletLayerGrossWeight, null),
    
    // palletUOMType: state?.palletUOMType,
    palletQtyOfStorePack: toSafeFloat(state?.palletQtyOfStorePack, null),
    palletEANForStorePack: state?.palletEANForStorePack,
    palletQtyOfBaseItem: toSafeFloat(state?.palletQtyOfBaseItem, null),
    palletQtyOfNextLowerItem: toSafeFloat(state?.palletQtyOfNextLowerItem, null),
    palletOrderableUnit: state?.palletOrderableUnit,
    palletDespatchUnit: state?.palletDespatchUnit, 
    palletTradeItemMeasurementsLengthDepth: toSafeFloat(state?.palletTradeItemMeasurementsLengthDepth, null),
    palletTradeItemMeasurementsWidth: toSafeFloat(state?.palletTradeItemMeasurementsWidth, null),
    palletTradeItemMeasurementsHeight: toSafeFloat(state?.palletTradeItemMeasurementsHeight, null),
    palletGrossWeight: toSafeFloat(state?.palletGrossWeight, null),

    priceListPriceCurrency: state?.priceListPriceCurrency,
    priceListPrice: toSafeFloat(state?.priceListPrice, null),
    priceRecommendedConsumerPrice: toSafeFloat(state?.priceRecommendedConsumerPrice, null),

    purchasingDataSupplierArticleNo: state?.purchasingDataSupplierArticleNo,
    // purchasingDataSupplierSubrange: state?.purchasingDataSupplierSubrange,
    purchasingDataIncoterm: state?.purchasingDataIncoterm,
    purchasingDataPickupLocation: state?.purchasingDataPickupLocation,

    qrLabelingSwedish: state?.qrLabelingSwedish,
    qrMedicalClaims: state?.qrMedicalClaims,
    qrMarketingClaimsCosmetic: state?.qrMarketingClaimsCosmetic,
    qrEfsaUlValues: state?.qrEfsaUlValues,
    qrNutritionClaims: state?.qrNutritionClaims,
    qrHealthClaims: state?.qrHealthClaims,
    qrFoodLabeling: state?.qrFoodLabeling,
    qrMisleadingSalesTerms: state?.qrMisleadingSalesTerms,
    qrFoodHygiene: state?.qrFoodHygiene,
    qrClassificationLabelingPackaging: state?.qrClassificationLabelingPackaging,
    qrElectronic: state?.qrElectronic,
    qrAdditivesInFeed: state?.qrAdditivesInFeed,
    qrFeedLabeling: state?.qrFeedLabeling,
    qrRelevantFeedCertifications: state?.qrRelevantFeedCertifications,

    shortSalesDescription: state?.shortSalesDescription,
    animalFoodIngredients: state?.animalFoodIngredients,
    eggPackerApprovalNumber: state?.eggPackerApprovalNumber,
    dosageAndUsageInstructions: state?.dosageAndUsageInstructions,
    preparationInstructions: state?.preparationInstructions,
    responsibleLabelingApprovalNumber: state?.responsibleLabelingApprovalNumber,
    feedAdditives: state?.feedAdditives,
    analyticalConstituentsFeed: state?.analyticalConstituentsFeed,
    feedingInstructions: state?.feedingInstructions,
    animalAgeGroup: state?.animalAgeGroup,
    CMRSubstances: state?.CMRSubstances,
    informationHazardousSubstances: state?.informationHazardousSubstances,
    specifyCEStandards: state?.specifyCEStandards,
    explanationNoUDI: state?.explanationNoUDI,
    regulatedSubstance: state?.regulatedSubstance,

    cmrSubstance: state?.cmrSubstance,
    reachSubstance: state?.reachSubstance,
    bestBeforeDate: state?.bestBeforeDate,
    childrenUnder16: state?.childrenUnder16,
    complianceEg12232009: state?.complianceEg12232009,
    complianceReach19072006: state?.complianceReach19072006,
    reachSubstancesAnnex19072006: state?.reachSubstancesAnnex19072006,
    hazardousSubstancesOver1: state?.hazardousSubstancesOver1,
    complianceProductSafety2004451: state?.complianceProductSafety2004451,
    ceMarking: state?.ceMarking,
    complianceMedicalTechProducts: state?.complianceMedicalTechProducts,
    complianceHaccp: state?.complianceHaccp,
    plasticTrayPackaging: state?.plasticTrayPackaging,
    checkUniqueIdentification: state?.checkUniqueIdentification,

    fishProductionMethod: state?.fishProductionMethod,
    fishCatchMethod: state?.fishCatchMethod,
    catchArea: state?.catchArea,
    eggWeightClass: state?.eggWeightClass,
    recyclingType: state?.recyclingType,
    recycling_amount: state?.recycling_amount,
    signalWords: state?.signalWords,
    preparedForm: state?.preparedForm,
    categoryWeightloss: state?.categoryWeightloss,
    complianceBiocid: state?.complianceBiocid,
    complianceBiocidalTreated: state?.complianceBiocidalTreated,
    confirmGuidanceSwedishElectronics: state?.confirmGuidanceSwedishElectronics,
    complianceToyDirective: state?.complianceToyDirective,
    complianceMetalsSkinContact: state?.complianceMetalsSkinContact,
    approvalDietFeed: state?.approvalDietFeed,
    complianceSunscreenRegulation: state?.complianceSunscreenRegulation,
    sunProtectionFactor: state?.sunProtectionFactor,

    additionalCharacteristics: state?.additionalCharacteristics,
    hazardSymbols: state?.hazardSymbols,
    hazardStatements: state?.hazardStatements,
    securityNotices: state?.securityNotices,
    animalCategoriesForFeed: state?.animalCategoriesForFeed,

    bulkImportFile: state?.bulkImportFile,
    isDraft: !!state?.isDraft,

    recognizeImages: state?.recognizeImages || [],
  };

  transformCategoryWrite(inputFields);

  // if(state.descriptive_size_amount > 0 && isDefined(state.descriptive_size_unit)) {
  // }
  inputFields.descriptive_size_amount = state.descriptive_size_amount;
  if (state.descriptive_size_unit && state.descriptive_size_unit !== '') {
    inputFields.descriptive_size_unit = state.descriptive_size_unit;
  }
  inputFields.descriptive_size_amount_extra = state.descriptive_size_amount_extra;
  const images = imageUrls?.map(i => addAutoEnhanceParam(i));
  if (!isCreatingNew) {
    inputFields.id = product?.id;
    inputFields.images = images;

    inputFields.producer = {
      username: state.producerUsername,
      name: state.producerName,
      email: state.producerEmail,
    };
  } else {
    inputFields.image_src = head(images);
    inputFields.additionalImages = tail(images);
  }

  if (isFruktOrGreen(state.foodlaCategory, rootCategory)) inputFields.classification = state.classification;

  if (isCreatingNew) {
    return {
      variables: {
        input: inputFields,
      },
    };
  } else {
    // Product notes
    // const productNotes = [];
    // ORGANIZATION_SPECIFIC_FIELDS.COOP.forEach(f => {
    //   if (isDefined(state[f.fieldName])) {
    //     const note = {
    //       fieldName: f.fieldName,
    //       [f.inputType]: f.transformer(state[f.fieldName]),
    //     };
    //     productNotes.push(note);
    //   }
    // });

    const updatingInput = {
      variables: {
        input: inputFields,
      },
    };

    if (APPLICATION_ROLES.SUPERUSER) {
      // updatingInput.variables.productNotes = productNotes;
      updatingInput.variables.id = product?.id;
    }

    return updatingInput;
  }
}; 

const extractProductNoteValues = (existingProduct) => {
  const notes = path(['productNotes'], existingProduct);
  // console.log(`TEST: ${JSON.stringify(notes, null, 2)}`);
  if (isNotDefined(notes)) return {};
  const pN = notes.reduce((ack, key) => {
    ack[key.fieldName] = PRODUCT_NOTE_TYPES_EXTRACTOR[key.__typename](key.value);
    return ack;
  }, {});

  console.log(`PRODUCT_NOTES: ${JSON.stringify(pN)}`);
  return pN;
};

export const getShowRed = (state) => {
  const nutritionalKeys = Object.keys(state).filter(x => requiredNutritionalFields.includes(x) && !hasValue(state[x]));
  return nutritionalKeys.length > 0 && nutritionalKeys.length < requiredNutritionalFields.length;
};

export const getProducerName = (product, producer) => {
  let producerName = '';
  if (producer?.name && producer.name.trim() !== '') {
    producerName = producer.name;
  } else if (product?.producer?.name && product.producer.name.trim() !== '') {
    producerName = product.producer.name;
  }
  return producerName;
}

export const getBrand = (product, producer) => {
  // console.log('!!!producer', producer);
  const producerName = getProducerName(product, producer);
  // console.log('!!!producerName', producerName);
  if (!product?.brand || product.brand.trim() === '') {
    return producerName;
  } else {
    // console.log('!!!brand', product.brand);
    return product.brand;
  }
};

export const getBrandFood = (product, producer) => {
  const producerName = getProducerName(product, producer);
  if (!product?.brand_food || product.brand_food.trim() === '') {
    return producerName;
  } else {
    return product.brand_food;
  }
};

const getParentOrg = (organizationGroupIdentifier) => {
  if (organizationGroupIdentifier && organizationGroupIdentifier.trim() !== '') {
    const regex = /^(.+)\/.+/i;
    const result = organizationGroupIdentifier.match(regex);
    // console.log('organizationGroupIdentifier', organizationGroupIdentifier);
    // console.log('result', result);
    return result?.[1];
  }
  return undefined;
}

export const getInitialState = (existingProduct, producerData) => {
  // const producerName = getProducerName(existingProduct, producerData);
  const producer = isEmpty(producerData) ? existingProduct?.producer : producerData;

  let foodlaCategory = pathOr(null, ['foodlaCategory'], existingProduct);
  if (typeof foodlaCategory === 'string') {
    foodlaCategory = null;
  }
  const state = {
    id: pathOr('', ['id'], existingProduct),
    title: pathOr('', ['title'], existingProduct),
    pricing: pathOr('', ['pricing'], existingProduct),
    short_text: pathOr('', ['short_text'], existingProduct),
    longitude: path(['longitude'], existingProduct) || pathOr(0, ['longitude'], producer),
    latitude: path(['latitude'], existingProduct) || pathOr(0, ['latitude'], producer),
    location: path(['location'], existingProduct) || pathOr('', ['location'], producer),
    EAN: pathOr('', ['EAN'], existingProduct),
    EAN2: pathOr('', ['EAN2'], existingProduct),
    BNR: pathOr('', ['BNR'], existingProduct),
    producerUsername: pathOr('', ['username'], producer),
    producerIsStore: pathOr(false, ['isStore'], producer),
    producerStoreId: pathOr('', ['storeId'], producer),
    producerId: pathOr(null, ['id'], producer),
    producerName: pathOr('', ['name'], producer),
    producerPhone: pathOr('', ['phone'], producer),
    producerEmail: pathOr('', ['email'], producer),
    manufacturerGln: pathOr('', ['gln'], existingProduct),
    producerOrg: pathOr('', ['organizationGroupIdentifier'], producer),
    producerParentOrg: getParentOrg(pathOr('', ['organizationGroupIdentifier'], producer)),
    producerOrganizations: pathOr(["coop"], ['organizations'], producer),
    video: pathOr('', ['video'], existingProduct),
    unit: pathOr('', ['unit'], existingProduct),
    adminStatus: pathOr('', ['adminStatus'], existingProduct),
    foodlaCategory,
    classification: pathOr('', ['classification'], existingProduct),
    alcoholPercentage: pathOr(0, ['alcoholPercentage'], existingProduct),
    ingredient_statement: pathOr('', ['ingredient_statement'], existingProduct),
    created_at: pathOr(null, ['created_at'], existingProduct),
    updated_at: pathOr(null, ['updated_at'], existingProduct),

    energi_kj: pathOr('', ['energi_kj'], existingProduct),
    energi_kcal: pathOr('', ['energi_kcal'], existingProduct),
    fett: pathOr('', ['fett'], existingProduct),
    mattatFett: pathOr('', ['mattatFett'], existingProduct),
    enkelomattatFett: pathOr('', ['enkelomattatFett'], existingProduct),
    flerromattatFett: pathOr('', ['flerromattatFett'], existingProduct),
    kolhydrat: pathOr('', ['kolhydrat'], existingProduct),
    sockerarter: pathOr('', ['sockerarter'], existingProduct),
    polyoler: pathOr('', ['polyoler'], existingProduct),
    starkelse: pathOr('', ['starkelse'], existingProduct),
    fiber: pathOr('', ['fiber'], existingProduct),
    protein: pathOr('', ['protein'], existingProduct),
    salt: pathOr('', ['salt'], existingProduct),
    nutritional_unit: pathOr('GRAM', ['nutritional_unit'], existingProduct),
    // vitaminer: pathOr('', ['vitaminer'], existingProduct),

    vitaminA: pathOr('', ['vitaminA'], existingProduct),
    vitaminD: pathOr('', ['vitaminD'], existingProduct),
    vitaminE: pathOr('', ['vitaminE'], existingProduct),
    vitaminK: pathOr('', ['vitaminK'], existingProduct),
    vitaminC: pathOr('', ['vitaminC'], existingProduct),
    tiamin: pathOr('', ['tiamin'], existingProduct),
    riboflavin: pathOr('', ['riboflavin'], existingProduct),
    niacin: pathOr('', ['niacin'], existingProduct),
    vitaminB6: pathOr('', ['vitaminB6'], existingProduct),
    folsyra: pathOr('', ['folsyra'], existingProduct),
    vitaminB12: pathOr('', ['vitaminB12'], existingProduct),
    biotin: pathOr('', ['biotin'], existingProduct),
    pantotensyra: pathOr('', ['pantotensyra'], existingProduct),
    kalium: pathOr('', ['kalium'], existingProduct),
    klorid: pathOr('', ['klorid'], existingProduct),
    kalcium: pathOr('', ['kalcium'], existingProduct),
    fosfor: pathOr('', ['fosfor'], existingProduct),
    magnesium: pathOr('', ['magnesium'], existingProduct),
    jarn: pathOr('', ['jarn'], existingProduct),
    zink: pathOr('', ['zink'], existingProduct),
    koppar: pathOr('', ['koppar'], existingProduct),
    mangan: pathOr('', ['mangan'], existingProduct),
    fluorid: pathOr('', ['fluorid'], existingProduct),
    selen: pathOr('', ['selen'], existingProduct),
    krom: pathOr('', ['krom'], existingProduct),
    molybden: pathOr('', ['molybden'], existingProduct),
    jod: pathOr('', ['jod'], existingProduct),
    
    descriptive_size: pathOr('', ['descriptive_size'], existingProduct),
    descriptive_size_amount: pathOr(0, ['descriptive_size_amount'], existingProduct),
    descriptive_size_amount_extra: pathOr([], ['descriptive_size_amount_extra'], existingProduct),
    descriptive_size_unit: pathOr('', ['descriptive_size_unit'], existingProduct),
    trade_item_temperature_information: pathOr('', ['trade_item_temperature_information'], existingProduct),
    place_of_item_activity: pathOr('', ['place_of_item_activity'], existingProduct),
    additional_required_information: pathOr('', ['additional_required_information'], existingProduct),
    quantity_in_stock: pathOr(1, ['quantity_in_stock'], existingProduct),
    consumer_size_to_order_size_ratio: pathOr(1, ['consumer_size_to_order_size_ratio'], existingProduct),
    approve: pathOr([], ['approve'], existingProduct),
    labels: pathOr([], ['labels'], existingProduct),
    ...extractProductNoteValues(existingProduct),

    color: pathOr('', ['color'], existingProduct),
    non_food_ingredients: pathOr('', ['non_food_ingredients'], existingProduct),
    material: pathOr('', ['material'], existingProduct),
    dimensions: pathOr('', ['dimensions'], existingProduct),
    capacity: pathOr('', ['capacity'], existingProduct),
    // gross_weight: pathOr('', ['gross_weight'], existingProduct),
    // package_size: pathOr(0, ['package_size'], existingProduct),
    // package_size_unit: pathOr('', ['package_size_unit'], existingProduct),
    // country_of_origin: pathOr('', ['country_of_origin'], existingProduct),
    country_of_manufacturing: pathOr(null, ['country_of_manufacturing'], existingProduct),
    brand: getBrand(existingProduct, producerData),
    brand_food: getBrandFood(existingProduct, producerData),
    // brand: defaultTo(pathOr('', ['producer', 'name'], existingProduct), pathOr('', ['brand'], existingProduct)),
    // brand_food: defaultTo(pathOr('', ['producer', 'name'], existingProduct), pathOr('', ['brand_food'], existingProduct)),

    min_temperature: pathOr(null, ['min_temperature'], existingProduct),
    max_temperature: pathOr(null, ['max_temperature'], existingProduct),

    gross_weight_num: pathOr('', ['gross_weight_num'], existingProduct),
    gross_height: pathOr('', ['gross_height'], existingProduct),
    gross_width: pathOr('', ['gross_width'], existingProduct),
    gross_depth: pathOr('', ['gross_depth'], existingProduct),
    search_terms: pathOr('', ['search_terms'], existingProduct),
    imageUnderProcess: pathOr(false, ['imageUnderProcess'], existingProduct),
    ignoreEANLengthError: pathOr(false, ['ignoreEANLengthError'], existingProduct),

    isMagazine: pathOr(false, ['isMagazine'], existingProduct),
    price: pathOr(null, ['price'], existingProduct),
    priceEAN: pathOr(null, ['priceEAN'], existingProduct),
    issueActiveDate: pathOr(null, ['issueActiveDate'], existingProduct),
    lastreturndate: pathOr(null, ['lastreturndate'], existingProduct),
    totalNumberIssues: pathOr(null, ['totalNumberIssues'], existingProduct),
    currentIssueNumber: pathOr(null, ['currentIssueNumber'], existingProduct),
    currentIssueYear: pathOr(null, ['currentIssueYear'], existingProduct),
    alternativeTitle: pathOr(null, ['alternativeTitle'], existingProduct),

    // detailsDescriptiveSizeOfConsumerPackAmount: pathOr(null, ['detailsDescriptiveSizeOfConsumerPackAmount'], existingProduct),
    // detailsDescriptiveSizeOfConsumerPackUnit: pathOr(null, ['detailsDescriptiveSizeOfConsumerPackUnit'], existingProduct),
    // detailsEffectiveDate: pathOr(null, ['detailsEffectiveDate'], existingProduct),

    // basicDataCategory: pathOr(null, ['basicDataCategory'], existingProduct),
    basicDataMixCategory: pathOr(null, ['basicDataMixCategory'], existingProduct),
    basicDataTradeItemVariableUnit: pathOr(null, ['basicDataTradeItemVariableUnit'], existingProduct),
    basicDataTaxClassification: pathOr(null, ['basicDataTaxClassification'], existingProduct),
    basicDataPriceComparisonContentType: pathOr(null, ['basicDataPriceComparisonContentType'], existingProduct),
    basicDataAvailabilityDateFrom: pathOr(null, ['basicDataAvailabilityDateFrom'], existingProduct),
    articleFirstOrderDate: pathOr(null, ['articleFirstOrderDate'], existingProduct),
    articleFirstDelivery: pathOr(null, ['articleFirstDelivery'], existingProduct),
    // basicDataAvailabilityDateTo: pathOr(null, ['basicDataAvailabilityDateTo'], existingProduct),
    basicDataCbuIndicator: pathOr(null, ['basicDataCbuIndicator'], existingProduct),

    // consumerPackQtyOfBaseItem: pathOr(null, ['consumerPackQtyOfBaseItem'], existingProduct),
    consumerPackOrderableUnit: pathOr(null, ['consumerPackOrderableUnit'], existingProduct),
    consumerPackDespatchUnit: pathOr(null, ['consumerPackDespatchUnit'], existingProduct),
    consumerPackNetWeight: pathOr(null, ['consumerPackNetWeight'], existingProduct),

    // storePackUOMType: pathOr(null, ['storePackUOMType'], existingProduct),
    storePackEANForStorePack: pathOr(null, ['storePackEANForStorePack'], existingProduct),
    storePackOrderableUnit: pathOr(false, ['storePackOrderableUnit'], existingProduct),
    storePackDespatchUnit: pathOr(false, ['storePackDespatchUnit'], existingProduct),
    storePackTradeItemMeasurementsLengthDepth: pathOr(null, ['storePackTradeItemMeasurementsLengthDepth'], existingProduct),
    storePackTradeItemMeasurementsWidth: pathOr(null, ['storePackTradeItemMeasurementsWidth'], existingProduct),
    storePackTradeItemMeasurementsHeight: pathOr(null, ['storePackTradeItemMeasurementsHeight'], existingProduct),
    storePackGrossWeight: pathOr(null, ['storePackGrossWeight'], existingProduct),

    // transportUOMType: pathOr(null, ['transportUOMType'], existingProduct),
    transportEANForStorePack: pathOr(null, ['transportEANForStorePack'], existingProduct),
    transportQtyOfBaseItem: pathOr(null, ['transportQtyOfBaseItem'], existingProduct),
    transportQtyOfNextLowerItem: pathOr(null, ['transportQtyOfNextLowerItem'], existingProduct),
    transportOrderableUnit: pathOr(false, ['transportOrderableUnit'], existingProduct),
    transportDespatchUnit: pathOr(false, ['transportDespatchUnit'], existingProduct),
    transportTradeItemMeasurementsLengthDepth: pathOr(null, ['transportTradeItemMeasurementsLengthDepth'], existingProduct),
    transportTradeItemMeasurementsWidth: pathOr(null, ['transportTradeItemMeasurementsWidth'], existingProduct),
    transportTradeItemMeasurementsHeight: pathOr(null, ['transportTradeItemMeasurementsHeight'], existingProduct),
    transportGrossWeight: pathOr(null, ['transportGrossWeight'], existingProduct),
    transportMinTemperature: pathOr(null, ['transportMinTemperature'], existingProduct),
    transportMaxTemperature: pathOr(null, ['transportMaxTemperature'], existingProduct),

    // palletLayerUOMType: pathOr(null, ['palletLayerUOMType'], existingProduct),
    palletLayerEANForStorePack: pathOr(null, ['palletLayerEANForStorePack'], existingProduct),
    palletLayerQtyOfBaseItem: pathOr(null, ['palletLayerQtyOfBaseItem'], existingProduct),
    palletLayerQtyOfNextLowerItem: pathOr(null, ['palletLayerQtyOfNextLowerItem'], existingProduct),
    palletLayerOrderableUnit: pathOr(false, ['palletLayerOrderableUnit'], existingProduct),
    palletLayerDespatchUnit: pathOr(false, ['palletLayerDespatchUnit'], existingProduct),
    palletLayerTradeItemMeasurementsLengthDepth: pathOr(null, ['palletLayerTradeItemMeasurementsLengthDepth'], existingProduct),
    palletLayerTradeItemMeasurementsWidth: pathOr(null, ['palletLayerTradeItemMeasurementsWidth'], existingProduct),
    palletLayerTradeItemMeasurementsHeight: pathOr(null, ['palletLayerTradeItemMeasurementsHeight'], existingProduct),
    palletLayerGrossWeight: pathOr(null, ['palletLayerGrossWeight'], existingProduct),

    // palletUOMType: pathOr(null, ['palletUOMType'], existingProduct),
    palletQtyOfStorePack: pathOr(null, ['palletQtyOfStorePack'], existingProduct),
    palletEANForStorePack: pathOr(null, ['palletEANForStorePack'], existingProduct),
    palletQtyOfBaseItem: pathOr(null, ['palletQtyOfBaseItem'], existingProduct),
    palletQtyOfNextLowerItem: pathOr(null, ['palletQtyOfNextLowerItem'], existingProduct),
    palletOrderableUnit: pathOr(false, ['palletOrderableUnit'], existingProduct),
    palletDespatchUnit: pathOr(false, ['palletDespatchUnit'], existingProduct),
    palletTradeItemMeasurementsLengthDepth: pathOr(null, ['palletTradeItemMeasurementsLengthDepth'], existingProduct),
    palletTradeItemMeasurementsWidth: pathOr(null, ['palletTradeItemMeasurementsWidth'], existingProduct),
    palletTradeItemMeasurementsHeight: pathOr(null, ['palletTradeItemMeasurementsHeight'], existingProduct),
    palletGrossWeight: pathOr(null, ['palletGrossWeight'], existingProduct),

    priceListPriceCurrency: pathOr(null, ['priceListPriceCurrency'], existingProduct),
    priceListPrice: pathOr(null, ['priceListPrice'], existingProduct),
    priceRecommendedConsumerPrice: pathOr(null, ['priceRecommendedConsumerPrice'], existingProduct),

    purchasingDataSupplierArticleNo: pathOr(null, ['purchasingDataSupplierArticleNo'], existingProduct),
    // purchasingDataSupplierSubrange: pathOr(null, ['purchasingDataSupplierSubrange'], existingProduct),
    purchasingDataIncoterm: pathOr(null, ['purchasingDataIncoterm'], existingProduct),
    purchasingDataPickupLocation: pathOr(null, ['purchasingDataPickupLocation'], existingProduct),

    qrLabelingSwedish: pathOr(null, ['qrLabelingSwedish'], existingProduct),
    qrMedicalClaims: pathOr(null, ['qrMedicalClaims'], existingProduct),
    qrMarketingClaimsCosmetic: pathOr(null, ['qrMarketingClaimsCosmetic'], existingProduct),
    qrEfsaUlValues: pathOr(null, ['qrEfsaUlValues'], existingProduct),
    qrNutritionClaims: pathOr(null, ['qrNutritionClaims'], existingProduct),
    qrHealthClaims: pathOr(null, ['qrHealthClaims'], existingProduct),
    qrFoodLabeling: pathOr(null, ['qrFoodLabeling'], existingProduct),
    qrMisleadingSalesTerms: pathOr(null, ['qrMisleadingSalesTerms'], existingProduct),
    qrFoodHygiene: pathOr(null, ['qrFoodHygiene'], existingProduct),
    qrClassificationLabelingPackaging: pathOr(null, ['qrClassificationLabelingPackaging'], existingProduct),
    qrElectronic: pathOr(null, ['qrElectronic'], existingProduct),
    qrAdditivesInFeed: pathOr(null, ['qrAdditivesInFeed'], existingProduct),
    qrFeedLabeling: pathOr(null, ['qrFeedLabeling'], existingProduct),
    qrRelevantFeedCertifications: pathOr(null, ['qrRelevantFeedCertifications'], existingProduct),

    shortSalesDescription: pathOr(null, ['shortSalesDescription'], existingProduct),
    animalFoodIngredients: pathOr(null, ['animalFoodIngredients'], existingProduct),
    eggPackerApprovalNumber: pathOr(null, ['eggPackerApprovalNumber'], existingProduct),
    dosageAndUsageInstructions: pathOr(null, ['dosageAndUsageInstructions'], existingProduct),
    preparationInstructions: pathOr(null, ['preparationInstructions'], existingProduct),
    responsibleLabelingApprovalNumber: pathOr(null, ['responsibleLabelingApprovalNumber'], existingProduct),
    feedAdditives: pathOr(null, ['feedAdditives'], existingProduct),
    analyticalConstituentsFeed: pathOr(null, ['analyticalConstituentsFeed'], existingProduct),
    feedingInstructions: pathOr(null, ['feedingInstructions'], existingProduct),
    animalAgeGroup: pathOr(null, ['animalAgeGroup'], existingProduct),
    CMRSubstances: pathOr(null, ['CMRSubstances'], existingProduct),
    informationHazardousSubstances: pathOr(null, ['informationHazardousSubstances'], existingProduct),
    specifyCEStandards: pathOr(null, ['specifyCEStandards'], existingProduct),
    explanationNoUDI: pathOr(null, ['explanationNoUDI'], existingProduct),
    regulatedSubstance: pathOr(null, ['regulatedSubstance'], existingProduct),

    cmrSubstance: pathOr(null, ['cmrSubstance'], existingProduct),
    reachSubstance: pathOr(null, ['reachSubstance'], existingProduct),
    bestBeforeDate: pathOr(null, ['bestBeforeDate'], existingProduct),
    childrenUnder16: pathOr(null, ['childrenUnder16'], existingProduct),
    complianceEg12232009: pathOr(null, ['complianceEg12232009'], existingProduct),
    complianceReach19072006: pathOr(null, ['complianceReach19072006'], existingProduct),
    reachSubstancesAnnex19072006: pathOr(null, ['reachSubstancesAnnex19072006'], existingProduct),
    hazardousSubstancesOver1: pathOr(null, ['hazardousSubstancesOver1'], existingProduct),
    complianceProductSafety2004451: pathOr(null, ['complianceProductSafety2004451'], existingProduct),
    ceMarking: pathOr(null, ['ceMarking'], existingProduct),
    complianceMedicalTechProducts: pathOr(null, ['complianceMedicalTechProducts'], existingProduct),
    complianceHaccp: pathOr(null, ['complianceHaccp'], existingProduct),
    plasticTrayPackaging: pathOr(null, ['plasticTrayPackaging'], existingProduct),
    checkUniqueIdentification: pathOr(null, ['checkUniqueIdentification'], existingProduct),

    fishProductionMethod: pathOr(null, ['fishProductionMethod'], existingProduct),
    fishCatchMethod: pathOr(null, ['fishCatchMethod'], existingProduct),
    catchArea: pathOr(null, ['catchArea'], existingProduct),
    eggWeightClass: pathOr(null, ['eggWeightClass'], existingProduct),
    recyclingType: pathOr(null, ['recyclingType'], existingProduct),
    recycling_amount: pathOr(null, ['recycling_amount'], existingProduct),
    signalWords: pathOr(null, ['signalWords'], existingProduct),
    preparedForm: pathOr(null, ['preparedForm'], existingProduct),
    categoryWeightloss: pathOr(null, ['categoryWeightloss'], existingProduct),
    complianceBiocid: pathOr(null, ['complianceBiocid'], existingProduct),
    complianceBiocidalTreated: pathOr(null, ['complianceBiocidalTreated'], existingProduct),
    confirmGuidanceSwedishElectronics: pathOr(null, ['confirmGuidanceSwedishElectronics'], existingProduct),
    complianceToyDirective: pathOr(null, ['complianceToyDirective'], existingProduct),
    complianceMetalsSkinContact: pathOr(null, ['complianceMetalsSkinContact'], existingProduct),
    approvalDietFeed: pathOr(null, ['approvalDietFeed'], existingProduct),
    complianceSunscreenRegulation: pathOr(null, ['complianceSunscreenRegulation'], existingProduct),
    sunProtectionFactor: pathOr(null, ['sunProtectionFactor'], existingProduct),

    additionalCharacteristics: pathOr(null, ['additionalCharacteristics'], existingProduct),
    hazardSymbols: pathOr(null, ['hazardSymbols'], existingProduct),
    hazardStatements: pathOr(null, ['hazardStatements'], existingProduct),
    securityNotices: pathOr(null, ['securityNotices'], existingProduct),
    animalCategoriesForFeed: pathOr(null, ['animalCategoriesForFeed'], existingProduct),
    isDraft: pathOr(false, ['isDraft'], existingProduct),

    recognizeImages: pathOr([], ['recognizeImages'], existingProduct),
  };

  if (!state.search_terms) {
    state.search_terms = getItems(state);
  }

  // console.log('state producer', state);
  state.showRed = getShowRed(state);
  transformCategoryRead(state);
  return state;
};

export const isFoodCategory = state => !isNonFoodProduct(state);
