import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import InputAdornment from '@material-ui/core/InputAdornment';

interface IInputCheckerProps {
  error?: boolean;
  showRed?: boolean;
}

const InputChecker = ({ error, showRed }: IInputCheckerProps) => {
  let color = '#56aba9';
  if (error) {
    if (showRed) {
      color = 'red';
    } else {
      color = '#e39c19';
    }
  }
  return (
    <InputAdornment position="start" style={{ marginRight: -4 }}>
      <CheckIcon style={{ color }} />
    </InputAdornment>
  );
};

export default InputChecker;
