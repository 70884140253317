import React, { useState, useContext } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import InputTextField from '../../inputfields/InputTextField';
import ImageUploader from '../../fileuploader/ImageUploader';
import ImageSorter from '../../images/ImageSorter';
import GoogleAutoComplete from '../../maps/GoogleAutoComplete';
import { isNotDefined, isDefined, hasValue } from '../../../utils/helpers';
import COLORS from '../../../constants/colors';
import ListDropDown from '../../inputfields/ListDropDown';
import { KeycloakContext } from '../../../components/Secured';
import DetailedInputLabel from '../../inputfields/InputLabel';
import Province from './input/province';
import Description from './input/description';
import WhatToDo from './input/whattodo';
import ShortStory from './input/short-story';
import StripeCustomers from './input/stripe-customers';
import ProducerName from './input/name';
import ProducerOrganizations from './input/organizations';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#56aba9',
  },
}));

const daysOfWeekVariants = [
  null,
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const timeVariants = [
  null,
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
];

export const Delivery = ({state, setState, index = 0, delivery}) => {
  // let delivery = state?.delivery;
  // if (delivery) {
  //   delivery = delivery[index];
  // }
  // if (!delivery) {
  //   delivery = {};
  // }
  const handleDeliveryField = (e) => {
    let newDelivery = state?.delivery;
    if (!newDelivery) {
      newDelivery = [];
    }
    // if (newState.delivery.length < index + 1) {
    //   for (let i=newState.delivery.length; i < index + 1; i++) {
    //     newState.delivery.push({});
    //   }
    // }
    newDelivery[index].__typename = undefined;
    newDelivery[index][e.target.name] = e.target.value;
    setState({...state, delivery: newDelivery});
  };
  return (
    <p>
      <FormControlLabel
        control={
          <InputTextField
            inputName="deliveryArea"
            value={delivery.deliveryArea}
            onChange={handleDeliveryField}
            noFullWidth={true}
            dense={true}
            style={{width: 150}}
          />
        }
        label="Leveransomrade: "
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <ListDropDown
            listData={daysOfWeekVariants.map(x => ({value: x}))}
            handleChange={handleDeliveryField}
            value={delivery.orderDay}
            targetName={`orderDay`}
            style={{ width: 150 }}
            dropDownStyle={{ height: 19 }}
          />
        }
        label="Beställningsdag: "
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <ListDropDown
            listData={timeVariants.map(x => ({value: x}))}
            handleChange={handleDeliveryField}
            value={delivery.stopTime}
            targetName="stopTime"
            style={{ width: 150 }}
            dropDownStyle={{ height: 19 }}
          />
        }
        label="Stopptid: "
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <ListDropDown
            listData={daysOfWeekVariants.map(x => ({value: x}))}
            handleChange={handleDeliveryField}
            value={delivery.deliveryDay}
            targetName="deliveryDay"
            style={{ width: 150 }}
            dropDownStyle={{ height: 19 }}
          />
        }
        label="Leveransdag: "
        labelPlacement="start"
      />
      <IconButton
        style={{color: "red", flex: 0.05}}
        onClick={(e) => {
          e.preventDefault();
          const newDelivery = state?.delivery;
          if (newDelivery) {
            newDelivery.splice(index, 1);
            // if (newDelivery.length === 0) {
            //   newDelivery.push({});
            // }
            setState({...state, delivery: newDelivery});
          }
        }}
      >
        <RemoveCircleIcon fontSize="large" />
      </IconButton>
    </p>
  );
};

export const GET_STRIPE_CUSTOMERS = gql`
  query getStripeCustomers {
    getStripeCustomers {
      id
      name
      email
    }
  }
`;

const ProducerForm = ({
  creatingNew,
  formValid,
  handleChange,
  handleOnSubmit,

  inputDescription,
  inputWhattodo,
  inputAwards,
  inputShortStory,
  inputSignText,

  inputEmail,
  inputPhone,
  inputLatitude,
  inputLongitude,
  inputLocation,
  inputProvince,
  inputName,
  inputStoreId,
  inputNonLocal,
  inputUsername,
  inputUserEnabled,
  inputDeliverySchedules,
  onImageDropped,
  isUpdating,
  onImageOrderChanged,
  onDeleteOrKeepImagePressed,
  images,
  withOutImagePreview,
  imagesToBeRemoved,
  onLockUserName,
  userNameIsLocked,
  onSelectAddress,
  onEnableUser,
  onNonLocalProducer,
  onErrorLoadingAddressComponent,
  addressError,
  isSuperuser,

  inputDeliveryArea,
  inputOrderDay,
  inputStopTime,
  inputDeliveryDay,
  inputDeliveryDescription,
  inputDelivery,
  inputStripeCustomerId,
  inputSupplierVatNumber,
  inputSupplierGLN,
  inputNameNordicDistributor,
  website,
  inputSupplierContactPerson,
  inputSupplierContactPersonTitle,
  inputSupplierContactComplaints,
  inputSupplierPhoneComplaints,
  inputSupplierEmailComplaints,
  inputRequiredInformationComplaints,
  inputSupplierEmailOrder,
  inputLimitNoFreightFee,
  inputMinValuePerOrder,
  setState,
  state,
  stateLoading,

  showMandatory,
}) => {
  console.log('initState', state);
  const classes = useStyles();
  const [googleAddressInput, toggleGoogleAddressInput] = useState(true);

  const keycloakCtx = useContext(KeycloakContext);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');
  const producerStore = state?.isStore;

  const readonlyStoreId = isStore;
  const showStoreId = state?.isStore && (isSuperuser || (isStore && hasValue(state?.storeId)));
  const [loadingTiff, setLoadingTiff] = React.useState(false);

  const toggleAddressInput = () => {
    toggleGoogleAddressInput(!googleAddressInput);
  };

  const shouldShowInputFields = !creatingNew || (creatingNew && userNameIsLocked);

  const { data: stripeData, loading: stripeDataLoading, error: stripeDataError } = useQuery(GET_STRIPE_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
  });

  const sortComparer = (a, b) => {
    return a.name.localeCompare(b.name);
  };
  const getEmailDomain = (email) => {
    if (email && email.trim() !== '') {
      const domain = email.includes('@') && email.split("@").pop();
      if (
        domain === 'gmail.com'
        || domain === 'outlook.com'
        || domain === 'hotmail.com'
      ) return undefined;
      if (domain) return domain;
    }
    return undefined;
  };

  const emailComparer = (x) => {
    if (x?.email && state?.email && x.email.trim() !== '' && state.email.trim() !== '') {
      if (x.email === state.email) return true;
      const domain1 = getEmailDomain(x.email);
      const domain2 = getEmailDomain(state.email);
      if (domain1 && domain2) {
        if (domain1 === domain2) {
          return true;
        }
      }
    }
    return false;
  };
  const [stripeCustomers, setStripeCustomers] = React.useState([]);
  // const stripeCustomers = stripeData
  //   ?.getStripeCustomers
  //   ?.sort(sortComparer)
  //   ?.map((x) => ({
  //     value: x.id,
  //     displayName: `${x.name} (${x.email})`,
  //     suggested: x.name === state.name || emailComparer(x),
  //   }));

  React.useEffect(() => {
    if (!stripeDataLoading && !stripeDataError && stripeData) {
      const customers = stripeData
        ?.getStripeCustomers
        ?.sort(sortComparer)
        ?.map(x => ({
          value: x.id,
          displayName: `${x.name} (${x.email})`,
        }));
      setStripeCustomers(customers || []);
      if (customers && state?.stripeCustomerId && state.stripeCustomerId.trim() !== '') {
        const val = state.stripeCustomerId;
        const customer = stripeData?.getStripeCustomers?.find((x) => x.id === val);
        if (customer) {
          console.log('initState, useEffect index', customer);
          setState({
            ...state, 
            stripeCustomerName: customer.name, 
            stripeCustomerEmail: customer.email,
            stripeCustomerDeleted: customer.deleted,
          });
        }
      }
    }
  }, [stripeData, stripeDataLoading, stripeDataError]);

  const handleStripeNameChange = (event) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      const val = !stateValue || stateValue === '' ? null : stateValue;
      // stateName && setState({ ...state, [stateName]: val });

      if (val && stateName) {
        const item = stripeData?.getStripeCustomers?.find(x => x.value === val);
        if (item) {
          console.log('initState, handleStripeNameChange');
          setState({
            ...state, 
            stripeCustomerName: item.name, 
            stripeCustomerEmail: item.email, 
            stripeCustomerId: val,
            stripeCustomerDeleted: item.deleted,
          });
        }
      }
  };    
  return (
    <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
      {isSuperuser && (
        <ProducerOrganizations
          state={state}
          setState={setState}
        />
      )}
      {creatingNew && !userNameIsLocked && (
        <Typography>
          Ditt Username är unikt för dig som producent och går inte att ändra men det kommer bara vara synligt för dig.
          Ditt synliga visningsnamn anges i nästa steg.
        </Typography>
      )}
      <InputTextField
        autoFocus
        inputName="username"
        label="Username"
        value={inputUsername}
        onChange={handleChange}
        required={true}
        disabled={(creatingNew && userNameIsLocked) || (!creatingNew && !isSuperuser)}
        withLabel={userNameIsLocked || !creatingNew}
        labelOptions={{
          heading: 'Username',
          text:
            'Ditt Username är unikt för dig som producent och går inte att ändra men det kommer bara vara synligt för dig.',
        }}
      />

      {creatingNew && !userNameIsLocked && (
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isNotDefined(inputUsername)}
          onClick={onLockUserName}
        >
          Lock username
        </Button>
      )}
      {shouldShowInputFields && (
        <>
          <ProducerName
            state={state}
            setState={setState}
          />
          {showStoreId && (
          <InputTextField
            inputName="storeId"
            label="ButiksID"
            value={inputStoreId}
            onChange={handleChange}
            disabled={readonlyStoreId}
            // required={true}
            // showRed={true}
          />
          )}
          {isSuperuser && !state.isStore && (
            <StripeCustomers
              state={state}
              setState={setState}
              dataLoading={stripeDataLoading || stateLoading}
            />
          )}
          <InputTextField
            inputName="supplierVatNumber"
            label="VAT nummer"
            value={inputSupplierVatNumber}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'VAT nummer',
            }}
          />
          <InputTextField
            inputName="supplierGLN"
            label="GLN"
            value={inputSupplierGLN}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Företagets GLN nummer',
            }}
          />
          <InputTextField
            inputName="nameNordicDistributor"
            label="Namn nordisk distributör"
            value={inputNameNordicDistributor}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Namn nordisk distributör',
            }}
          />
          <h3>KONTAKTINFORMATION</h3>
          <Divider />
          <InputTextField
            inputName="website"
            placeholder="Ex. www.hemsida.se"
            value={website}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Hemsida',
            }}
          />
          <InputTextField
            inputName="supplierContactPerson"
            label="Namn"
            value={inputSupplierContactPerson}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Kontaktperson',
            }}
          />
          <InputTextField
            inputName="supplierContactPersonTitle"
            label="Titel / roll"
            value={inputSupplierContactPersonTitle}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Titel',
            }}
          />
          <InputTextField
            inputName="email"
            label="Email"
            value={inputEmail}
            onChange={handleChange}
            required={true}
            withLabel={true}
            labelOptions={{
              heading: 'Email',
              text: 'Din email kommer användas för all kontakt mellan dig, Foodla, och återförsäljare.',
            }}
            showRed={true}
            errorText='Vi vill gärna att du fyller i det här fältet innan du kan uppdatera din profil, tack!'
          />
          <InputTextField
            inputName="phone"
            label="Telephone"
            value={inputPhone}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Telephone',
              // text: 'TODO: add description',
            }}
          />
          <h3>REKLAMATIONSINFORMATION</h3>
          <InputTextField
            inputName="supplierContactComplaints"
            label="Kontaktnamn"
            value={inputSupplierContactComplaints}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Kontaktperson vid klagomål',
            }}
          />
          <InputTextField
            inputName="supplierPhoneComplaints"
            label="Telefon"
            value={inputSupplierPhoneComplaints}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Telefon',
            }}
          />
          <InputTextField
            inputName="supplierEmailComplaints"
            label="Email"
            value={inputSupplierEmailComplaints}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Email',
            }}
          />
          <InputTextField
            inputName="requiredInformationComplaints"
            label="Ex: Ange artikelnummer, defekt, etc."
            value={inputRequiredInformationComplaints}
            onChange={handleChange}
            withLabel={true}
            labelOptions={{
              heading: 'Vilken information behöver ni vid en reklamation?',
            }}
          />
          <h3 style={{marginBottom: 0}}>HISTORIEN OM ER</h3>
          <p>Det här materialet kommer att användas i kommunikation med konsumenten. Exempelvis för egen online-profil hos återförsäljare
          (se https://www.coop.se/handla/gardsbutiken), butiksskyltning, kampanjer och utskick, artiklar i medlemstidningar etc.</p>
          <Divider />
          {/* <InputTextField
            inputName="story"
            // label="Description"
            value={inputStory}
            onChange={handleChange}
            // required
            multiline
            multilineRows={2}
            multilineMax={5}
            // withLabel={true}
            // labelOptions={{
            //   heading: 'Description',
            //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
            // }}
          /> */}
          <ImageUploader
            withLabel
            onImageDropped={onImageDropped}
            multiple
            labelOptions={{
              heading: 'Pictures',
              text: 'Första bilden i listan blir visningsbild i listor mm.',
            }}
            withOutImagePreview
            disabled={isNotDefined(inputUsername)}
            setLoadingTiff={setLoadingTiff}
          />
          <ImageSorter
            images={images}
            onDeleteOrKeepImagePressed={onDeleteOrKeepImagePressed}
            onOrderChanged={onImageOrderChanged}
            imagesToBeRemoved={imagesToBeRemoved}
          />

          <Description
            state={state}
            setState={setState}
          />
          <WhatToDo
            state={state}
            setState={setState}
          />
          <InputTextField
            inputName="awards"
            // label="Description"
            value={inputAwards}
            onChange={handleChange}
            // required
            multiline
            multilineRows={4}
            multilineMax={10}
            withLabel={true}
            labelOptions={{
              heading: 'Har ni eller era produkter fått några utmärkelser?',
            //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
            }}
          />
          <ShortStory
            state={state}
            setState={setState}
          />
          <InputTextField
            inputName="signText"
            // label="Description"
            value={inputSignText}
            onChange={handleChange}
            // required
            multiline
            multilineRows={4}
            multilineMax={6}
            withLabel={true}
            maxLength={70}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            labelOptions={{
              heading: 'Skylttext för Balkskyltar (max 70 tecken):',
            //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
            }}
          />
          {!isStore && (
            <>
              <h3 style={{marginBottom: 0}}>ORDERINFORMATION</h3>
              <p>Foodla har en funktion för orderhantering som används av en del butiker. Dessa uppgifter behövs för att ordersystmet ska fungera korrekt.</p>
              <Divider />
              {state?.delivery && state.delivery.map((delivery, index) => {
                return (
                  <Delivery
                    delivery={delivery}
                    state={state}
                    setState={setState}
                    index={index}
                  />
                );
              })}
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <AddCircleIcon style={{color: "rgb(0, 163, 71)"}} fontSize="small" />
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  // if (!inputDelivery) {
                  //   inputDelivery = [];
                  // }
                  // inputDelivery.push({});
                  // console.log('inputDelivery', inputDelivery);
                  let delivery = state?.delivery;
                  if (!delivery) {
                    delivery = [];
                  }
                  delivery.push({});
                  setState({...state, delivery});
                }}> Ytterligare beställnings och leveranstillfällen</a>
              </div>
              <InputTextField
                inputName="deliverySchedules"
                // label="Description"
                value={inputDeliverySchedules}
                onChange={handleChange}
                // required
                multiline
                multilineRows={4}
                multilineMax={10}
                withLabel={true}
                labelOptions={{
                  heading: 'Övriga leveransdetaljer kan anges här:',
                //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
                }}
              />
              {/* <InputTextField
                inputName="deliverySchedules"
                label="Leveransschema"
                value={inputDeliverySchedules}
                onChange={handleChange}
                required
                multiline
                multilineRows={2}
                multilineMax={6}
                withLabel={true}
                labelOptions={{
                  heading: 'Leveransschema',
                  text:
                    'Beskriv detaljer kring leverans, exempel: "Vi levererar måndagar. För leverans måndag behöver vi få order senast torsdag veckan innan."',
                }}
              /> */}
              <InputTextField
                inputName="supplierEmailOrder"
                label="Email order"
                value={inputSupplierEmailOrder}
                onChange={handleChange}
                withLabel={true}
                labelOptions={{
                  heading: 'Email för orderläggning',
                }}
              />
              <InputTextField
                inputName="limitNoFreightFee"
                value={inputLimitNoFreightFee}
                onChange={handleChange}
                withLabel={true}
                placeholder="Ex. 10 000 kr"
                labelOptions={{
                  heading: 'Fraktfri gräns',
                }}
              />
              <InputTextField
                inputName="minValuePerOrder"
                value={inputMinValuePerOrder}
                onChange={handleChange}
                withLabel={true}
                placeholder="Ex. 5 000 kr"
                labelOptions={{
                  heading: 'Minsta ordervärde',
                }}
              />
            </>
          )}

          <h3 style={{marginBottom: 0}}>ER ADRESS</h3>
          <p>Adressen behöver vi för att koppla ihop rätt producenter med rätt områden, butiker och försäljningskanaler.</p>
          <Divider />
          <FormControlLabel
            control={
              <Switch checked={googleAddressInput} onChange={toggleAddressInput} name="addressInput" color="primary" />
            }
            label="Use Google address search"
          />
          {googleAddressInput && (
            <>
              <GoogleAutoComplete
                onSelectAddress={onSelectAddress}
                onFailureLoadingComponent={onErrorLoadingAddressComponent}
                currentAddress={inputLocation}
                withLabel
                labelOptions={{
                  heading: 'Adress till producent',
                  text: 'Denna information används för att matcha producenter med försäljningsomårde.',
                }}
              />
              {addressError && (
                <p>
                  Could not load address selection. You can still upload product. Current address on product will still
                  be used. Contact Foodla to correct this later.
                </p>
              )}
            </>
          )}
          <>
            {!googleAddressInput && (
              <InputTextField
                inputName="location"
                label="Address"
                value={inputLocation}
                onChange={handleChange}
                // required
              />
            )}
            <InputTextField
              inputName="latitude"
              label="Latitude"
              value={inputLatitude}
              onChange={handleChange}
              disabled={googleAddressInput}
              required={showMandatory}
            />
            <InputTextField
              inputName="longitude"
              label="Longitude"
              value={inputLongitude}
              onChange={handleChange}
              disabled={googleAddressInput}
              required={showMandatory}
            />
          </>
          <Province
            state={state}
            setState={setState}
          />

          {isSuperuser && isDefined(inputUserEnabled) && (
            <FormControlLabel
              control={<Switch size="medium" checked={inputUserEnabled} onChange={onEnableUser} color="primary" />}
              label={inputUserEnabled ? <span style={{ color: COLORS.mainGreen, fontSize: 18, fontWeight: 'bold' }}>Producer enabled</span> : <span style={{ color: COLORS.mainRed, fontSize: 18, fontWeight: 'bold' }}>Producer disabled</span>}
            />
          )}
          {isSuperuser && !producerStore && (
            <div>
            <FormControlLabel
              control={
                <Switch checked={inputNonLocal} onChange={onNonLocalProducer} name="nonLocal" color="primary" />
              }
              label="Non local food producer"
            />
            </div>
          )}
          {isUpdating ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!formValid}
            >
              {creatingNew ? 'Create producer' : 'Update producer'}
            </Button>
          )}
        </>
      )}
    </form>
  );
};

export default ProducerForm;
