import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import IProduct from '../../../../models/product';
import FloatInput from '../FloatInput';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import useViewport from 'hooks/useViewports';


interface IVitaminsProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  expand?: boolean;
  approve?: boolean;
}

interface IVitaminProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  title: string;
  field: keyof IProduct;
  unit: string;
  dri: number;
}

const Vitamin = ({ state, setState, title, field, unit, dri }: IVitaminProps) => {
  const { smUp } = useViewport();

  let value = 0;
  const f = state[field];
  if (f) {
    if (typeof f === 'number') {
      value = f;
    } else if (typeof f === 'string') {
      value = parseFloat(f.trim().replace(',', '.'));
    }
  }

  const renderDRI = () => {
    return (
      <span style={{ flex: 1, verticalAlign: 'middle', marginTop: 'auto', marginBottom: smUp ? 'auto' : 2 }}>
        &nbsp;&nbsp;{value ? `(${Math.round(value / dri * 100)}% av DRI)` : ''}
      </span>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: smUp ? 'row' : 'column', flex: 1 }}>
      <div style={{ flex: smUp ? '0 0 400px' : 1, display: 'flex', flexDirection: smUp ? 'row' : 'column' }}>
        <div style={{ flex: smUp ? '0 0 calc(200px - 17px)' : undefined, display: smUp ? 'block' : 'flex' }}>
          <h3 style={{ marginBottom: smUp ? 16 : 0 }}>
            {title}:
          </h3>
          {!smUp && renderDRI()}
        </div>
        <div style={{ flex: smUp ? '0 0 180px' : 1, display: 'flex' }}>
          <FloatInput
            name={field}
            state={state}
            setState={setState}
            style={{ flex: 1 }}
          />
          <h3 style={{ flex: '0 0 30px' }}>&nbsp;{unit}</h3>
        </div>
      </div>
      {smUp && renderDRI()}
    </div>
  );
}

interface IVitamin {
  title: string;
  field: keyof IProduct;
  unit: string;
  dri: number;
}

const vitamins: IVitamin[] = [
  { title: 'Vitamin A', field: 'vitaminA', unit: 'μg', dri: 800 },
  { title: 'Vitamin D', field: 'vitaminD', unit: 'μg', dri: 5 },
  { title: 'Vitamin E', field: 'vitaminE', unit: 'mg', dri: 12 },
  { title: 'Vitamin K', field: 'vitaminK', unit: 'μg', dri: 75 },
  { title: 'Vitamin C', field: 'vitaminC', unit: 'mg', dri: 80 },
  { title: 'Tiamin', field: 'tiamin', unit: 'mg', dri: 1.1 },
  { title: 'Riboflavin', field: 'riboflavin', unit: 'mg', dri: 1.4 },
  { title: 'Niacin', field: 'niacin', unit: 'mg', dri: 16 },
  { title: 'Vitamin B6', field: 'vitaminB6', unit: 'mg', dri: 1.4 },
  { title: 'Folsyra', field: 'folsyra', unit: 'μg', dri: 200 },
  { title: 'Vitamin B12', field: 'vitaminB12', unit: 'μg', dri: 2.5 },
  { title: 'Biotin', field: 'biotin', unit: 'μg', dri: 50 },
  { title: 'Pantotensyra', field: 'pantotensyra', unit: 'mg', dri: 6 },
  { title: 'Kalium', field: 'kalium', unit: 'mg', dri: 2000 },
  { title: 'Klorid', field: 'klorid', unit: 'mg', dri: 800 },
  { title: 'Kalcium', field: 'kalcium', unit: 'mg', dri: 800 },
  { title: 'Fosfor', field: 'fosfor', unit: 'mg', dri: 700 },
  { title: 'Magnesium', field: 'magnesium', unit: 'mg', dri: 375 },
  { title: 'Järn', field: 'jarn', unit: 'mg', dri: 14 },
  { title: 'Zink', field: 'zink', unit: 'mg', dri: 10 },
  { title: 'Koppar', field: 'koppar', unit: 'mg', dri: 1 },
  { title: 'Mangan', field: 'mangan', unit: 'mg', dri: 2 },
  { title: 'Fluorid', field: 'fluorid', unit: 'mg', dri: 3.5 },
  { title: 'Selen', field: 'selen', unit: 'μg', dri: 55 },
  { title: 'Krom', field: 'krom', unit: 'μg', dri: 40 },
  { title: 'Molybden', field: 'molybden', unit: 'μg', dri: 50 },
  { title: 'Jod', field: 'jod', unit: 'μg', dri: 150 },
];

export const Vitamins = ({ state, setState, expand, approve }: IVitaminsProps) => {
  const canApprove = state?.approve?.find((x) => x.field === 'vitamins_minerals');
  return (
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={expand ? ["root"] : undefined}
            style={{flex: canApprove ? 0.9 : 1}}
        >
          <TreeItem nodeId="root" label={<u>Vitaminer & Mineraler</u>}>
            {vitamins.map((vitamin) => (
                <Vitamin
                    state={state}
                    setState={setState}
                    title={vitamin.title}
                    field={vitamin.field}
                    unit={vitamin.unit}
                    dri={vitamin.dri}
                />
            ))}
          </TreeItem>
        </TreeView>
        {approve && canApprove && (
          <div style={{flex: 0.1}}>
            <ThreeWaySwitch
                name="vitamins_minerals"
                state={state}
                setState={setState}
            />
          </div>
        )}
      </div>
  );
};

export default Vitamins;
