// Dependencies
import React, { useContext, useState, useEffect, useMemo, ReactNode } from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Card, TextField} from '@material-ui/core';
import { path } from 'ramda';
import { isNil } from 'lodash';

// Components
import ProductCardView from './components/ProductCardView';
import ProductListView from './components/ProductListView';
import PagePagination from '../../Pagination';
import LoadingSkeleton from '../../loadingIndicator/ListLoadingSkeleton';
import { KeycloakContext } from '../../Secured';
import { ResponseSnackBar } from '../../snackbar/SnackBar';

// Utils
import { getUserRole } from '../../../utils/userrole';
import { isDefined, toSafeNumber, toSafeFloat, isNumber } from '../../../utils/helpers';
import { addOrChangeQueryParam, pushToWindowHistory, extractURLParam } from '../../../utils/urlUtils';
import { getNumberOfPages } from '../../../utils/pagination';

// Constants
import {PAGINATION, isValidSortType, isValidSortOrder} from '../../../constants/domain';
import {APPLICATION_ROLES} from '../../constants';
import {EListViewType, IProductCardListProps} from './types';
import {EMPTY_ARRAY} from "../../../constants/app";
import { transformCategoryRead } from 'utils/products';
import useSearchParams from 'hooks/useSearchParams';
import useCategoriesData from 'hooks/useCategoriesData';
import useViewport from 'hooks/useViewports';
import ProductCartListEmpty from './ProductCartListEmpty';

const INIT_PAGE_PARAM = 1;
const INIT_LIMIT_PARAM = 24;
const INIT_SORT_BY = PAGINATION.SORTING_TYPES.NONE;
const INIT_SORT_ORDER = PAGINATION.SORT_ORDERS.ASC;
// Using Stockholm coordinates initially if not set.
// 59.336378, 18.063125
const INIT_LATITUDE = 59.336378;
const INIT_LONGITUDE = 18.063125;

const ProductCardList = ({
     loading: loadingProp,
     category,
     selectedProductId,
     productsQuery,
     queryVariables,
     overrideOnClickCallback,
     producerId,
     queryListDataExtractor,
     summaryData: summaryDataProp,
     currentTab,
     notificationsData,
     showSearch = false,
     showSorting = false,
     showPagination = false,
     isExport,
     showDescriptiveSize,
     summaryRefetch,
     showEdit,
     showSuperuserNotifications,
     storeMe,
     listViewType = EListViewType.CARD,
     removeProductHandlerKey,
     showEAN,
     newEmptyUi,
 }: IProductCardListProps) => {
    const { mdUp } = useViewport();
    const { loading: loadingCategory, rootCategory } = useCategoriesData();
    const { searchParams, updateSearchParams } = useSearchParams();
    const summaryName = isExport ? 'exportSummary' : 'productsSummary';
    let products;
    // Handle pagination information.
    const nrPending = path([summaryName, 'details', 'numOfPending'], summaryDataProp) as number;
    const nrApproved = path([summaryName, 'details', 'numOfApproved'], summaryDataProp) as number;
    const nrDisapproved = path([summaryName, 'details', 'numOfDisapproved'], summaryDataProp) as number;

    let pageParam = extractURLParam('page', (window as any).location);
    pageParam = toSafeNumber(pageParam, INIT_PAGE_PARAM);

    // Handle sorting params
    let sortByInit: string | null = extractURLParam('sortby', (window as any).location);
    const lsSortBy = localStorage.getItem('products-sortBy');
    if (isDefined(sortByInit) && isValidSortType(sortByInit)) {
    } else if (isDefined(lsSortBy) && isValidSortType(lsSortBy)) {
        sortByInit = lsSortBy;
    } else {
        sortByInit = INIT_SORT_BY;
    }
    // sortByInit = isDefined(sortByInit) && isValidSortType(sortByInit) ? sortByInit : INIT_SORT_BY;
    let sortOrderInit: string | null = extractURLParam('order', (window as any).location);
    const lsSortOrder = localStorage.getItem('products-sortOrder');
    if (isDefined(sortOrderInit) && isValidSortOrder(sortOrderInit)) {
    } else if (isDefined(lsSortOrder) && isValidSortOrder(lsSortOrder)) {
        sortOrderInit = lsSortOrder;
    } else {
        sortOrderInit = INIT_SORT_ORDER;
    }
    // sortOrderInit = isDefined(sortOrderInit) && isValidSortOrder(sortOrderInit) ? sortOrderInit : INIT_SORT_ORDER;

    let latitudeInit = extractURLParam('latitude', (window as any).location);
    const lsLatitude = localStorage.getItem('products-latitude');
    if (isDefined(latitudeInit)) {
        latitudeInit = toSafeFloat(latitudeInit, undefined);
    }
    if (!isDefined(latitudeInit) && isDefined(lsLatitude)) {
        latitudeInit = toSafeFloat(lsLatitude, undefined);
    }
    if (!isDefined(latitudeInit)) {
        // @ts-ignore
        latitudeInit = INIT_LATITUDE;
    }
    // latitudeInit = isDefined(latitudeInit) ? toSafeFloat(latitudeInit, INIT_LATITUDE) : INIT_LATITUDE;

    let longitudeInit = extractURLParam('longitude', (window as any).location);
    const lsLongitude = localStorage.getItem('products-longitude');
    if (isDefined(longitudeInit)) {
        longitudeInit = toSafeFloat(longitudeInit, undefined);
    }
    if (!isDefined(longitudeInit) && isDefined(lsLongitude)) {
        longitudeInit = toSafeFloat(lsLongitude, undefined);
    }
    if (!isDefined(longitudeInit)) {
        // @ts-ignore
        longitudeInit = INIT_LONGITUDE;
    }
    // longitudeInit = isDefined(longitudeInit) ? toSafeFloat(longitudeInit, INIT_LONGITUDE) : INIT_LONGITUDE;

    let limitInit = extractURLParam('limit', (window as any).location);
    const lsLimit = localStorage.getItem('products-limit');
    if (isDefined(limitInit)) {
        limitInit = toSafeNumber(limitInit, undefined);
    }
    if (!isDefined(limitInit) && isDefined(lsLimit)) {
        limitInit = toSafeNumber(lsLimit, undefined);
    }
    if (!isDefined(limitInit)) {
        // @ts-ignore
        limitInit = INIT_LIMIT_PARAM;
    }
    // limitInit = toSafeNumber(limitInit, INIT_LIMIT_PARAM);
    const [limit, setLimit] = useState(limitInit);

    // @ts-ignore
    const numberOfPages = getNumberOfPages(currentTab, nrPending, nrApproved, nrDisapproved, limit);
    // @ts-ignore
    const startPageCount = pageParam > numberOfPages ? numberOfPages : pageParam;

    // const [nrPages, setNrPages] = useState(numberOfPages);
    const [currentPage, setCurrentPage] = useState(startPageCount);

    const [sorting, setSorting] = useState(sortByInit);
    const [sortOrder, setSortOrder] = useState(sortOrderInit);
    const [coordinates, setCoordinates] = useState({ latitude: latitudeInit, longitude: longitudeInit});
    const [locationAccessDenied, setLocationAccessDenied] = useState(false);

    const [query, setQuery] = useState(searchParams.search || "");
    const [firstLoad, setFirstLoad] = useState(true);
    const [firstLoad2, setFirstLoad2] = useState(true);

    /*
    NOTE: We could use fetchMore with the apollo hook useQuery. This is a function passed back by calling useQuery hook.
    However, this merges the new fetch into the already fetched result and since we use pagination by page I feel that
    we can depend on the cache functionality in the apollo and use refetch with new variables. This also keeps us from
    handling large amount of data in the list unnecessarily.
    */
    const keycloakCtx = useContext(KeycloakContext);
    let userResourceRole = getUserRole(keycloakCtx);

    const qVariables = {
        ...queryVariables,
        textQuery: query,
        ...(showPagination || showSorting ? {
            pagePagination: {
                ...(showPagination ? {limit, page: currentPage} : {}),
                ...(showSorting ? {
                    sorting,
                    sortOrder,
                    longitude: coordinates.longitude,
                    latitude: coordinates.latitude,
                } : {}),
            },
        } : {}),
    };

    const { data: response, loading: loadingData, error, refetch } = useQuery(productsQuery, {
        variables: qVariables,
        fetchPolicy: 'cache-and-network',
    });

    const loading = loadingData || !!loadingProp;

    const data = useMemo(() => {
      if (response?.allProductsPagination) {
        return { allProducts: response.allProductsPagination.items };
      }
      return response;
    }, [response]);

    const summaryData = summaryDataProp || {
      productsSummary: {
        total: response?.allProductsPagination?.total || 0,
      },
    };

    // When user paginates.
    const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);

        const newURL = addOrChangeQueryParam('page', `${page}`, window.location);
        pushToWindowHistory(newURL, window, 'ProductPaginationPage');

        const qVariables = {
            ...queryVariables,
            textQuery: query,
            ...(showPagination || showSorting ? {
                pagePagination: {
                    ...(showPagination ? {limit, page} : {}),
                    ...(showSorting ? {
                        sorting,
                        sortOrder,
                        longitude: coordinates.longitude,
                        latitude: coordinates.latitude,
                    } : {}),
                },
            } : {}),
        };

        refetch(qVariables);
    };

    // When user changes the limit of products/page.
    const onLimitChanged = (changeToLimit: number) => {
        // @ts-ignore
        setLimit(changeToLimit);
        localStorage.setItem('products-limit', `${changeToLimit}`);

        const newURL = addOrChangeQueryParam('limit', `${changeToLimit}`, window.location);
        pushToWindowHistory(newURL, window, 'ProductPaginationLimit');

        // @ts-ignore
        const newPageCount = getNumberOfPages(currentTab, nrPending, nrApproved, nrDisapproved, changeToLimit);

        if (currentPage > newPageCount) {
            setCurrentPage(newPageCount);
        }

        // setNrPages(newPageCount);

        const qVariables = {
            ...queryVariables,
            textQuery: query,
            ...(showPagination || showSorting ? {
                pagePagination: {
                    ...(showPagination ? {
                        limit: changeToLimit,
                        page: currentPage > newPageCount ? newPageCount : currentPage,
                    } : {}),
                    ...(showSorting ? {
                        sorting,
                        sortOrder,
                        longitude: coordinates.longitude,
                        latitude: coordinates.latitude,
                    } : {}),
                },
            } : {}),
        };

        refetch(qVariables);
    };

    const getBrowserLocation = async () => {
        const positionSuccess = (position: any) => ({ lat: position.coords.latitude, lng: position.coords.longitude });

        setLocationAccessDenied(false);

        const location = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => resolve(positionSuccess(position)), () => reject('Error'));
        });

        return location;
    };

    // When user changes values to sort by.
    const onSortChanged = async (type: string, value: string) => {
        let newSorting = sorting;
        let newOrder = sortOrder;
        if (type === 'sorting') newSorting = value;
        else newOrder = value;

        if (type === 'sorting' && sorting !== value) {
          if (value === 'created_at') {
            newOrder = PAGINATION.SORT_ORDERS.DESC;
          } else {
            newOrder = PAGINATION.SORT_ORDERS.ASC;
          }
        }

        let latitude = coordinates.latitude;
        let longitude = coordinates.longitude;

        if(type === 'sorting' && value === 'distance') {
            try {
                const { lat, lng } = await getBrowserLocation() as any;
                latitude = lat;
                longitude = lng;
                setCoordinates({ latitude, longitude });
                localStorage.setItem('products-latitude', latitude);
                localStorage.setItem('products-longitude', longitude);

            } catch(e) {
                setLocationAccessDenied(true);
                return;
            }
        }

        setSorting(newSorting);
        localStorage.setItem('products-sortBy', `${newSorting}`);
        setSortOrder(newOrder);
        localStorage.setItem('products-sortOrder', `${newOrder}`);

        let newURL = addOrChangeQueryParam('sortby', newSorting, window.location);
        newURL = addOrChangeQueryParam('order', newOrder, { href: newURL }); // TODO: Fix this method, make it take the URL instead of the location object.

        if(isDefined(latitude) && isDefined(longitude)) {
            newURL = addOrChangeQueryParam('latitude', latitude, { href: newURL });
            newURL = addOrChangeQueryParam('longitude', longitude, { href: newURL });
        }
        pushToWindowHistory(newURL, window, 'ProductSorting');

        const qVariables = {
            ...queryVariables,
            textQuery: query,
            ...(showPagination || showSorting ? {
                pagePagination: {
                    ...(showPagination ? {
                        limit,
                        page: currentPage,
                    } : {}),
                    ...(showSorting ? {
                        sorting: newSorting,
                        sortOrder: newOrder,
                        latitude,
                        longitude,
                    } : {}),
                },
            } : {}),
        };

        refetch(qVariables);
    };

    let listData;
    if (isDefined(data) && !error) {
        listData = queryListDataExtractor ? queryListDataExtractor(data) : (isExport ? data?.exportProducts : data?.allProducts);
    }

    // If the limit has changed, we might have to adjust which page we are viewing since the nr of pages with more products
    // on each page could exceed the total amount of pages required.
    // if ((isNumber(nrPages) && isNumber(numberOfPages)) && (currentPage > numberOfPages)) setCurrentPage(numberOfPages);
    // If the limit has changed. The number of pages calculated may differ from whats been set in the state. This is checked and updated here.
    // if ((isNumber(nrPages) && isNumber(numberOfPages)) && (nrPages !== numberOfPages)) setNrPages(numberOfPages);
    
    useEffect(() => {
      if (!isNil(pageParam)) {
        setCurrentPage(pageParam);
      }
    }, [pageParam]);

    useEffect(() => {
        if (firstLoad2) {
            setFirstLoad2(false);
        } else {
            setQuery("");
            setCurrentPage(0);
        }
    }, [currentTab]);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
        } else {
            setCurrentPage(0);
            const qVariables = {
                ...queryVariables,
                textQuery: query,
                ...(showPagination || showSorting ? {
                    pagePagination: {
                        ...(showPagination ? {
                            limit,
                            page: 0,
                        } : {}),
                        ...(showSorting ? {
                            sorting,
                            sortOrder,
                            longitude: coordinates.longitude,
                            latitude: coordinates.latitude,
                        } : {}),
                    },
                } : {}),
            };

            refetch(qVariables);
        }
    }, [query]);

    if (isExport) {
        products = data?.exportProducts;
    } else {
        products = data?.products;
    }

    let noDataText: ReactNode | undefined;
    if (isExport) {
        if (!data?.exportProducts || data?.exportProducts.count === 0 || (Array.isArray(data?.exportProducts) && !data?.exportProducts?.length)) {
            noDataText = 'No products for export...';
        }
    } else {
        const hasData = (data?.allProducts?.count > 0)
            || (Array.isArray(data?.allProducts) && data?.allProducts?.length > 0)
            || (data?.products?.count > 0)
            || (Array.isArray(data?.products) && data?.products?.length > 0)
            || (data?.producer?.products?.count > 0)
            || (Array.isArray(data?.producer?.products) && data?.producer?.products?.length > 0)
            || (Array.isArray(data?.failedProducts) && data?.failedProducts?.length > 0)
            || (Array.isArray(data?.apiExportHistory?.edges) && data?.apiExportHistory?.edges?.length > 0);
        if (!hasData) {
            noDataText = 'No data...';
        }
    }
    if (newEmptyUi && noDataText) {
      noDataText = <ProductCartListEmpty />;
    }

    let count = 0;
    if (
        (userResourceRole === APPLICATION_ROLES.SUPERUSER
            || userResourceRole === APPLICATION_ROLES.PRODUCER
            || userResourceRole === APPLICATION_ROLES.STORE)
        && (summaryData?.productsSummary?.total)
    ) {
        count = summaryData.productsSummary.total;
    } else {
        if (products?.count) {
            count = products.count;
        }
    }

    return (
        <>
            {locationAccessDenied && <ResponseSnackBar title='Location' message="We could not access your location info. Can't sort by distance." success={false}/>}
            {isDefined(summaryData) && (showPagination || showSorting) && (
                <Card>
                    <PagePagination
                        // @ts-ignore
                        page={currentPage}
                        onPageChange={onPageChange}
                        count={count}
                        // @ts-ignore
                        limit={limit}
                        onLimitChanged={onLimitChanged}
                        disablePagination={!showPagination || !mdUp}
                        showSortingOptions={showSorting}
                        onSortChanged={onSortChanged}
                        sorting={sorting}
                        sortOrder={sortOrder}
                    />
                </Card>
            )}
            {showSearch && (
                <TextField
                    placeholder="Search by EAN or Product Name or Producer Name"
                    variant="outlined"
                    value={query}
                    style={{marginLeft: 10, marginRight: 10, marginTop: 10, width: 500, maxWidth: 'calc(100% - 20px)'}}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        updateSearchParams({ search: e.target.value });
                    }}
                />
            )}
            {/* <Autocomplete
        style={{margin: 10}}
        // searchAction={searchAction}
        // tryToAddItemToOrder={tryToAddItemToOrder}
      /> */}
            {/*@ts-ignore*/}
            {(loading || loadingCategory) && <LoadingSkeleton count={limit} listViewType={listViewType} />}
            {error && <p>Error :(</p>}
            {/* TODO add checking if no products */}
            {!(loading || loadingCategory) && noDataText && <p>{noDataText}</p>}
            {/* {!loading && (!data?.exportProducts || data.exportProducts.count === 0) && <p>{noDataText}</p>} */}

            {!error && !(loading || loadingCategory) && isDefined(listData) && (
                <>
                    {listViewType === EListViewType.CARD ? (
                        <ProductCardView
                            rootCategory={rootCategory}
                            showDescriptiveSize={showDescriptiveSize}
                            refetch={refetch}
                            list={(listData || EMPTY_ARRAY).map(transformCategoryRead)}
                            notificationsData={notificationsData}
                            userResourceRole={userResourceRole}
                            selectedProductId={selectedProductId}
                            overrideOnClickCallback={overrideOnClickCallback}
                            producerId={producerId}
                            currentCategory={category}
                            isExport={isExport}
                            summaryRefetch={summaryRefetch}
                            showEdit={showEdit}
                            showSuperuserNotifications={showSuperuserNotifications}
                            storeMe={storeMe}
                            searchString={query}
                            removeProductHandlerKey={removeProductHandlerKey}
                        />
                    ) : (
                        <ProductListView
                            rootCategory={rootCategory}
                            list={listData || EMPTY_ARRAY}
                            searchString={query}
                            showEAN={showEAN}
                        />
                    )}
                </>
            )}

            {isDefined(summaryData) && showPagination && (
                <Card>
                    <PagePagination
                        // @ts-ignore
                        page={currentPage}
                        onPageChange={onPageChange}
                        count={count}
                        // @ts-ignore
                        limit={limit}
                        onLimitChanged={onLimitChanged}
                    />
                </Card>
            )}
        </>
    );
};

export default ProductCardList;
