import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  ALL_PRODUCTS_PAGINATION_QUERY,
  MY_PRODUCTS_SUMMARY,
} from '../../graphql/queries';

import ProductCardList from '../../components/product/ProductCardList/ProductCardList';
import { KeycloakContext } from '../../components/Secured';
import InterestWhereProductShownOnline from '../../components/producer/InterestWhereProductShownOnline';
import useSearchParams from 'hooks/useSearchParams';
import InviteProducer from 'components/organization/InviteProducer';

const styles = theme => ({
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
});

const UserProductsPage = withStyles(styles)(({ classes, match }) => {
    const { searchParams, updateSearchParams } = useSearchParams();

    const [tab, setTab] = useState(searchParams?.tab || 'activeProducts');

    const itsMe = match?.url?.startsWith('/me/products');
    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
    const addProductUrl = itsMe ? '/me/products/addproduct' : '/products/addproduct';

    const {
      data: summaryData,
      loading: summaryLoading,
      error: summaryError,
      refetch: summaryRefetch,
    } = useQuery(MY_PRODUCTS_SUMMARY, {
      fetchPolicy: 'cache-and-network',
    });

    const newEmptyUi = (isProducer && match?.url === '/products') || (isStore && match?.url === '/me/products');

    let queryVariables = {};
    if (tab === 'activeProducts') queryVariables = { isActive: true };
    if (tab === 'draftProducts') queryVariables = { isDraft: true };
    if (tab === 'inactiveProducts') queryVariables = { isInactive: true };

    return (
      <>
        {isProducer && (
          <Paper style={{ marginBottom: 16 }}>
            <InterestWhereProductShownOnline />
          </Paper>
        )}
        <Box display="flex" justifyContent="end" alignItems="start" mb={2}>
          {isStore && <InviteProducer />}
        </Box>

        <Paper>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.toolbarBox}>
                    <Typography variant="body1" className={classes.title}>
                        Your Products
                    </Typography>
                </Box>
                <div>
                    {isProducer && (
                      <Button
                        component={RouterLink}
                        to='/bulk-import-new/select-category'
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<CloudUploadOutlinedIcon />}
                        style={{ marginRight: 8 }}
                      >
                          Add/update multiple products
                      </Button>
                    )}
                    <Button
                        component={RouterLink}
                        to={addProductUrl}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        New
                    </Button>
                </div>
            </Toolbar>
            <Divider />
            {!summaryLoading && !!summaryError && (
              <p style={{ color: 'red', paddingLeft: 24, fontWeight: 700 }}>
                Could not load summary data...
              </p>
            )}
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(e, val) => {
                updateSearchParams({ page: 1, tab: val, search: '' });
                setTab(val);
              }}
            >
              <Tab
                value="activeProducts"
                label={
                  <Badge color="secondary" badgeContent={summaryData?.myProductsSummary?.activeProductsCount}>
                    Aktiva produkter
                  </Badge>
                }
              />
              <Tab
                value="draftProducts"
                label={
                  <Badge color="secondary" badgeContent={summaryData?.myProductsSummary?.draftProductsCount}>
                    Utkastprodukter
                  </Badge>
                }
              />
              <Tab
                value="inactiveProducts"
                label={
                  <Badge color="secondary" badgeContent={summaryData?.myProductsSummary?.inactiveProductsCount}>
                    Inaktiva produkter
                  </Badge>
                }
              />
            </Tabs>
            <ProductCardList
                key={tab}
                loading={!summaryData && summaryLoading}
                showDescriptiveSize={true}
                selectedProductId={match?.params?.id}
                productsQuery={ALL_PRODUCTS_PAGINATION_QUERY}
                queryVariables={queryVariables}
                showSearch={true}
                showSorting={true}
                showPagination={true}
                showEdit={true}
                showSuperuserNotifications={isStore}
                storeMe={itsMe}
                newEmptyUi={newEmptyUi}
                summaryRefetch={summaryRefetch}
            />
        </Paper>
      </>
    );
});

export default UserProductsPage;
