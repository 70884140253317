import React, { useState, useContext } from 'react';

import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { path, map, uniqBy, includes, without, append, filter } from 'ramda';

import { ResponseSnackBar } from '../../components/snackbar/SnackBar';
import ProducerForm from '../../components/superuser/EditProducerForm';
import { GET_PRODUCER, GET_PRODUCERS, SUPERUSER_PRODUCERS_NOT_APPROVED_QUERY } from '../../graphql/queries';
import {
  MUTATION_SUPERUSER_UPDATE_PRODUCER,
  S3_SIGNED_REQUEST_MUTATION,
  MUTATION_SUPERUSER_ADD_PRODUCER,
} from '../../graphql/mutations';
import { isDefined, isNotDefined } from '../../utils/helpers';
import { uploadImages, getImagesForSorter } from '../../utils/images';
import { KeycloakContext } from '../../components/Secured';
import { SUPERUSER_APPROVAL } from '../../components/constants';

const getEmptyState = (id = '') => ({
  loaded: false,
  showMandatory: false,
  name: '',
  id: id,
  email: '',
  phone: '',
  username: '',
  description: '',
  latitude: 0,
  longitude: 0,
  location: '',
  province: '',
  deliverySchedules: '',
  profiles: [],
  userEnabled: false,
  nonLocal: false,
  stripeCustomerId: '',

  deliveryDescription: '',
  whattodo: '',
  awards: '',
  shortStory: '',
  signText: '',
  delivery: [],
  organizations: [],

  supplierVatNumber: '',
  supplierGLN: '',
  nameNordicDistributor: '',
  website: '',
  supplierContactPerson: '',
  supplierContactPersonTitle: '',
  supplierContactComplaints: '',
  supplierPhoneComplaints: '',
  supplierEmailComplaints: '',
  requiredInformationComplaints: '',
  supplierEmailOrder: '',
  limitNoFreightFee: '',
  minValuePerOrder: ''
});

const EditProducer = ({ match }) => {
  // TODO: Guard so that users editing their own page only can access and edit their own profile through id in param.
  const producerParamId = path(['params', 'id'], match);
  const isCreatingNewProducer = isNotDefined(producerParamId);
  const history = useHistory();

  const keycloakCtx = useContext(KeycloakContext);
  const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');

  const { data: producerData, loading: producerDataLoading, error: producerDataError } = useQuery(GET_PRODUCER, {
    variables: {
      id: producerParamId || '', // We expect this to fail if we are creating new. If we are, we don't use the returned data anyway.
    },
    fetchPolicy: 'cache-and-network',
  });

  const [state, setState] = React.useState(getEmptyState(match.params.id));

  const [files, setFiles] = useState([]);
  const [uploadDone, setUplodadDone] = useState(false);
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const [sortedImages, setImagesSorted] = useState([]);
  const [imagesToBeRemoved, setImagesToBeRemoved] = useState([]);
  const [imageUploadError, setImageUploadError] = useState(false);
  const [isUsernameLocked, lockUsername] = useState(false);
  const [addressError, setAddressError] = React.useState(false);

  const [s3SignedReuquest, { data: requestData, error: requestError }] = useMutation(S3_SIGNED_REQUEST_MUTATION, {
    refetchQueries: [],
    onCompleted: data => {
      const imagesForSorter = getImagesForSorter(state.profiles, data, files);
      setImagesSorted(imagesForSorter);
    },
  });

  const getS3Requests = images => {
    const files = images.map(i => ({ fileName: i.name, fileType: i.type }));
    s3SignedReuquest({
      variables: {
        input: {
          files,
          producerName: state.username,
        },
      },
    });
  };

  // Function for when images is dropped in ImageUploader.
  // 1. We set the files to the file-state.
  // 2. We ask BE for signed request in case we upload the images.
  const onImageDropped = images => {
    setFiles(images);
    getS3Requests(images);
  };

  // Images in sorter have a remove/delete button. This method gets called when clicked.
  const onImageActionClicked = image => {
    if (includes(image, imagesToBeRemoved)) {
      const toBeRemoved = without([image], imagesToBeRemoved);
      setImagesToBeRemoved(toBeRemoved);
    } else {
      const toBeRemoved = append(image, imagesToBeRemoved);
      const uniq = uniqBy(i => i.pictureUrl, toBeRemoved);
      setImagesToBeRemoved(uniq);
    }
  };

  const onLockUsernameClicked = () => {
    lockUsername(true);
  };

  const onSelectAddress = (locationInfo, latLng) => {
    setState({
      ...state,
      location: locationInfo,
      latitude: latLng.lat || state.latitude,
      longitude: latLng.lng || state.longitude,
    });
  };

  const onErrorLoadingAddressComponent = () => {
    setAddressError(true);
  };

  // This handles the updates of fields in the form.
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onEnableUser = event => {
    setState({ ...state, userEnabled: path(['target', 'checked'], event) });
  };

  const onNonLocalProducer = event => {
    setState({...state, nonLocal: path(['target', 'checked'], event)});
  };

  // When submitting.
  async function submitEditProducer(event, updateProducer) {
    event.preventDefault();
    const allProfileUrls = map(i => i.pictureUrl, sortedImages);
    const allToBeDeleted = map(i => i.pictureUrl, imagesToBeRemoved);
    const profilesToSave = without(allToBeDeleted, allProfileUrls);

    let delivery = state?.delivery;
    if (delivery) {
      delivery = delivery.map(d => ({...d, __typename: undefined}));
    }
    const input = {
      variables: {
        input: {
          name: state.name,
          email: state.email,
          phone: state.phone,
          username: state.username,
          description: state.description,
          latitude: parseFloat(state.latitude),
          longitude: parseFloat(state.longitude),
          location: state.location,
          province: state.province,
          deliverySchedules: isDefined(state.deliverySchedules) ? [state.deliverySchedules] : [],
          profiles: profilesToSave,
          status: state.userEnabled ? SUPERUSER_APPROVAL.ADMIN_APPROVED : SUPERUSER_APPROVAL.AWAITING_APPROVAL,
          stripeCustomerId: state.stripeCustomerId,

          deliveryDescription: state.deliveryDescription,
          whattodo: state.whattodo,
          awards: state.awards,
          shortStory: state.shortStory,
          signText: state.signText,
          delivery: delivery,
          storeId: state.storeId,
          nonLocal: state.nonLocal,

          organizations: state.organizations,

          supplierVatNumber: state.supplierVatNumber,
          supplierGLN: state.supplierGLN,
          nameNordicDistributor: state.nameNordicDistributor,
          website: state.website,
          supplierContactPerson: state.supplierContactPerson,
          supplierContactPersonTitle: state.supplierContactPersonTitle,
          supplierContactComplaints: state.supplierContactComplaints,
          supplierPhoneComplaints: state.supplierPhoneComplaints,
          supplierEmailComplaints: state.supplierEmailComplaints,
          requiredInformationComplaints: state.requiredInformationComplaints,
          supplierEmailOrder: state.supplierEmailOrder,
          limitNoFreightFee: state.limitNoFreightFee,
          minValuePerOrder: state.minValuePerOrder,
        },
      },
    };

    if (!isCreatingNewProducer) {
      input.variables.input.id = match.params.id;
    }

    const signedRequests = path(['getS3SignRequest', 'requests'], requestData);

    const requestsForProfilesToSave = isDefined(signedRequests)
      ? filter(r => includes(r.pictureUrl, profilesToSave), signedRequests)
      : [];

    if (files.length === 0 || requestError || isNotDefined(signedRequests) || requestsForProfilesToSave.length === 0) {
      updateProducer(input);
      return;
    }

    try {
      setImageIsUploading(true);

      if (!uploadDone) {
        await uploadImages(files, requestsForProfilesToSave);
      }

      setImageIsUploading(false);
      setUplodadDone(true);

      input.variables.input.profiles = profilesToSave;
      updateProducer(input);
    } catch (e) {
      setImageUploadError(true);
      setImageIsUploading(false);
      setUplodadDone(true);
      updateProducer(input);
    }
    return;
  }

  const gotoProducer = id => {
    if (isNotDefined(id)) return;
    history.push(`/superuser/producer/${id}/edit`);
  };

  const resetState = () => {
    setState(getEmptyState());
    setFiles([]);
    setUplodadDone(false);
    setImageIsUploading(false);
    setImagesSorted([]);
    setImagesToBeRemoved([]);
    setImageUploadError(false);
    lockUsername(false);
    setAddressError(false);
  };

  const validateForm = () =>
    (isCreatingNewProducer || state.loaded) &&
    !state?.duplicateCustomerError &&
    isDefined(state?.username) &&
    isDefined(state?.name) &&
    isDefined(state?.email) &&
    (!state?.showMandatory || (
    isDefined(state?.description) &&
    (isSuperuser || isDefined(state?.deliverySchedules || state?.delivery?.length > 0))));

  const errorLoadingOrgData = !producerDataLoading && producerDataError;
  const successLoadingOrgData = !errorLoadingOrgData && !producerDataLoading && producerData;

  if (!isCreatingNewProducer && !state.loaded && successLoadingOrgData) {
    const { producer } = producerData;

    // We handle status on producers accordling to:
    // 1. Users without status field in DB => enabled (legacy)
    // 2. Users with status field set is enabled if status is ADMIN_APPROVED
    let producerStatus = true;
    if (isDefined(producer.status)) {
      producerStatus = producer.status === SUPERUSER_APPROVAL.ADMIN_APPROVED ? true : false;
    }
    setState({
      loaded: true,
      id: producer.id,
      name: producer.name,
      email: producer.email,
      phone: producer.phone,
      username: producer.username,
      description: producer.description,
      latitude: producer.latitude,
      longitude: producer.longitude,
      location: producer.location,
      province: producer.province,
      deliverySchedules: isDefined(producer.deliverySchedules) ? producer.deliverySchedules[0] : [],
      profiles: isDefined(producer.profiles) ? producer.profiles : [],
      userEnabled: producerStatus,
      stripeCustomerId: producer.stripeCustomerId,

      deliveryDescription: producer.deliveryDescription,
      whattodo: producer.whattodo,
      awards: producer.awards,
      shortStory: producer.shortStory,
      signText: producer.signText,
      delivery: producer.delivery,
      isStore: producer.isStore,
      storeId: producer.storeId,
      nonLocal: producer.nonLocal,

      organizations: producer.organizations,

      supplierVatNumber: producer.supplierVatNumber,
      supplierGLN: producer.supplierGLN,
      nameNordicDistributor: producer.nameNordicDistributor,
      website: producer.website,
      supplierContactPerson: producer.supplierContactPerson,
      supplierContactPersonTitle: producer.supplierContactPersonTitle,
      supplierContactComplaints: producer.supplierContactComplaints,
      supplierPhoneComplaints: producer.supplierPhoneComplaints,
      supplierEmailComplaints: producer.supplierEmailComplaints,
      requiredInformationComplaints: producer.requiredInformationComplaints,
      supplierEmailOrder: producer.supplierEmailOrder,
      limitNoFreightFee: producer.limitNoFreightFee,
      minValuePerOrder: producer.minValuePerOrder,
    });

    setImagesSorted(
      getImagesForSorter(
        isDefined(producer.profiles) ? producer.profiles : [],
        isDefined(requestData) ? path(['getS3SignRequest', 'requests'], requestData) : [],
        files
      )
    );
  }

  React.useEffect(
    () => () => {
      sortedImages.forEach(i => i.isNew && URL.revokeObjectURL(i.imgSrc));
    },
    [sortedImages]
  );

  const refetchQueries = [{ query: GET_PRODUCERS }];
  if (!isCreatingNewProducer) refetchQueries.push({ query: GET_PRODUCER, variables: { id: match.params.id } });
  if (isSuperuser) refetchQueries.push({ query: SUPERUSER_PRODUCERS_NOT_APPROVED_QUERY });

  return (
    <>
      {!isCreatingNewProducer && errorLoadingOrgData && <h3>Failed to load data needed.</h3>}
      {(isCreatingNewProducer || successLoadingOrgData) && (
        <Mutation
          mutation={isCreatingNewProducer ? MUTATION_SUPERUSER_ADD_PRODUCER : MUTATION_SUPERUSER_UPDATE_PRODUCER}
          refetchQueries={refetchQueries}
          onCompleted={data => {
            if (isCreatingNewProducer) {
              resetState();
              return;
            }
            const newProfiles = path(['updateProducer', 'producer', 'profiles'], data);
            const imagesForSorter = getImagesForSorter(newProfiles, [], []);

            setUplodadDone(false);
            setImagesToBeRemoved([]);
            setFiles([]);
            setImagesSorted(imagesForSorter);
            setState({ ...state, profiles: newProfiles });
          }}
        >
          {(updateProducer, { data, loading, error }) => {
            let feedback = <></>;
            let loadingComponent = <></>;
            if (loading || imageIsUploading) loadingComponent = <CircularProgress />;
            if (data && !loading) {
              const accessor = isCreatingNewProducer ? 'createProducer' : 'updateProducer';
              const success = path([accessor, 'success'], data);
              const message = path([accessor, 'message'], data);
              const createdId = path([accessor, 'entityId'], data);

              const snackBarMessage = isCreatingNewProducer ? `${message} Click here to see.` : message;

              feedback = (
                <ResponseSnackBar
                  message={snackBarMessage}
                  success={success}
                  onSnackBarClicked={isCreatingNewProducer ? () => gotoProducer(createdId) : undefined}
                  additionalMessage={imageUploadError ? 'Failed to upload images. Contact support for help.' : ''}
                  additionalSuccess={!imageUploadError}
                />
              );
            }
            return (
              <>
                {!loading && !imageIsUploading && (
                  <CardContent>
                    <ProducerForm
                      creatingNew={isCreatingNewProducer}
                      withOutImagePreview
                      handleOnSubmit={event => submitEditProducer(event, updateProducer)}
                      inputName={state.name}
                      inputEmail={state.email}
                      inputPhone={state.phone}
                      inputStoreId={state.storeId}
                      inputNonLocal={state.nonLocal}
                      inputUsername={state.username}
                      inputDescription={state.description}
                      inputLatitude={state.latitude}
                      inputLongitude={state.longitude}
                      inputLocation={state.location}
                      inputProvince={state.province}
                      inputStripeCustomerId={state.stripeCustomerId}
                      inputDeliverySchedules={state.deliverySchedules}
                      inputUserEnabled={state.userEnabled}
                      handleChange={handleChange}
                      formValid={validateForm()}
                      isUpdating={producerDataLoading}
                      onImageDropped={onImageDropped}
                      onImageOrderChanged={setImagesSorted}
                      onDeleteOrKeepImagePressed={onImageActionClicked}
                      images={sortedImages}
                      imagesToBeRemoved={imagesToBeRemoved}
                      userNameIsLocked={isUsernameLocked}
                      onLockUserName={onLockUsernameClicked}
                      addressError={addressError}
                      onSelectAddress={onSelectAddress}
                      onEnableUser={onEnableUser}
                      onNonLocalProducer={onNonLocalProducer}
                      onErrorLoadingAddressComponent={onErrorLoadingAddressComponent}
                      isSuperuser={isSuperuser}

                      inputDeliveryDescription={state.deliveryDescription}
                      inputWhattodo={state.whattodo}
                      inputAwards={state.awards}
                      inputShortStory={state.shortStory}
                      inputSignText={state.signText}
                      inputDelivery={state.delivery}
                      inputSupplierVatNumber={state.supplierVatNumber}
                      inputSupplierGLN={state.supplierGLN}
                      inputNameNordicDistributor={state.nameNordicDistributor}
                      website={state.website}
                      inputSupplierContactPerson={state.supplierContactPerson}
                      inputSupplierContactPersonTitle={state.supplierContactPersonTitle}
                      inputSupplierContactComplaints={state.supplierContactComplaints}
                      inputSupplierPhoneComplaints={state.supplierPhoneComplaints}
                      inputSupplierEmailComplaints={state.supplierEmailComplaints}
                      inputRequiredInformationComplaints={state.requiredInformationComplaints}
                      inputSupplierEmailOrder={state.supplierEmailOrder}
                      inputLimitNoFreightFee={state.limitNoFreightFee}
                      inputMinValuePerOrder={state.minValuePerOrder}
                      setState={setState}
                      state={state}
                      stateLoading={producerDataLoading}
                    />
                    {feedback}
                  </CardContent>
                )}
                {loadingComponent}
              </>
            );
          }}
        </Mutation>
      )}
    </>
  );
};

export default EditProducer;
