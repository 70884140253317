import { FC, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorInfoIcon, { TErrorInfoData } from '../../../components/ErrorInfoIcon';
import { KeycloakContext } from 'components/Secured';

import useDataContext from '../../../hooks/useDataContext';
import useProductData from 'hooks/useProductData';

import { IImportColumn, IProductExcel } from '../../../types';
import { getCellValue, getErrorCell, isEanExist, isErrorCell } from '../../../utils/excel';

import CheckboxField from './CheckboxField';
import DateField from './DateField';

import { useStyles } from '../index.style';
import { useStyles as useCustomStyles } from './styles';

const EAN_USED_ERROR_MESSAGE =
  'EAN-koden redan existerar i Foodla. Kontakta Foodla om din EAN-kod verkar användas av någon annan.';

export interface ReviewTableBodyCellProps {
  isReview: boolean;
  column: IImportColumn;
  data: IProductExcel;
  className?: string;
}

/**
 *
 * don't use MUI's component in table body
 * - Using <TableCell>: x2 render time
 * - Using <Box>: x2 render time
 */
const ReviewTableBodyCell: FC<ReviewTableBodyCellProps> = ({ isReview, column, data, className }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const username = keycloakCtx.keycloak?.tokenParsed?.preferred_username;
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');

  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { onChangeDataValue, setDataList } = useDataContext();
  const { isLoadingProductsByEans, getProductsByEans } = useProductData();

  const keepRef = useRef({ latestEAN: '' });

  const isDateData = column.dataType === 'date';
  const isBooleanData = column.dataType === 'boolean';
  const isStringData = !isDateData && !isBooleanData;

  const [cellBoxElement, setCellBoxElement] = useState<HTMLDivElement | null>(null);

  const value = useMemo(() => getCellValue(column, data), [column, data]);
  const isError = useMemo(() => isErrorCell(column, { ...data, [column.field]: value }), [column, data, value]);
  const errorMessage = useMemo(() => getErrorCell(column, data), [column, data]);

  const isEanUsed = useMemo(
    () => isProducer && isEanExist(column, data, username),
    [column, data, username, isProducer]
  );

  const handleLoadSavedProductByEan = async (newEAN: string) => {
    keepRef.current.latestEAN = newEAN;

    const [savedProduct] = await getProductsByEans([newEAN]);

    // [keepRef.current.latestEAN]: always keep the latest EAN
    // Check and only apply for latest EAN
    if (savedProduct && keepRef.current.latestEAN === newEAN) {
      setDataList(oldState =>
        oldState.map(oldData => {
          if (oldData.EAN !== savedProduct.EAN) return oldData;
          return { ...oldData, _savedDataForCheckEAN: savedProduct };
        })
      );
    }
  };

  const handleChange = (value: string) => {
    onChangeDataValue(data.id || '', column.field, value);
    if (isProducer && column.field === 'EAN') {
      handleLoadSavedProductByEan(value);
    }
  };

  // When changes "page", "limit",... [ReviewTableBodyCell] will be remounted
  // Need to reset width for new [cellBoxElement] element
  useLayoutEffect(() => {
    if (!cellBoxElement || cellBoxElement.dataset.width) return;

    const headerCellElement = document.querySelector(`th [data-field='${column.field}']`) as HTMLElement;
    if (!headerCellElement || !headerCellElement.dataset.width) return;
    const { width } = headerCellElement.dataset;
    setTimeout(() => (cellBoxElement.style.width = `${parseFloat(width) + 2}px`), 0);
    cellBoxElement.dataset.width = width;
  }, [column.field, cellBoxElement]);

  const renderErrorInfo = () => {
    if (!isError && !isEanUsed) return null;

    const errors: TErrorInfoData[] = [];
    if (isError) {
      errors.push({ label: errorMessage });
    }
    if (isEanUsed) {
      errors.push({ label: EAN_USED_ERROR_MESSAGE });
    }

    return (
      <div style={{ position: isBooleanData ? 'absolute' : undefined, right: 0 }}>
        <ErrorInfoIcon key="error" errors={errors} />
      </div>
    );
  };

  const loading = isLoadingProductsByEans;

  return (
    <td className={clsx(classes.bodyCell, className)}>
      <div
        ref={setCellBoxElement}
        className={customClasses.cellWrapper}
        data-field={column.field}
        style={{ display: 'flex', alignItems: 'center', gap: 12 }}
      >
        <div className={isBooleanData ? '' : customClasses.newWrapper} style={{ flexGrow: 1 }}>
          {isDateData && <DateField column={column} data={data} onChange={handleChange} />}
          {isBooleanData && <CheckboxField column={column} data={data} onChange={handleChange} />}

          {isStringData && (
            <input
              className={customClasses.input}
              value={_.isNil(value) ? '' : String(value)}
              onChange={event => handleChange(event.target.value)}
            />
          )}
        </div>
        {loading ? (
          <div style={{ width: 24, height: 24 }}>
            <CircularProgress size={20} />
          </div>
        ) : (
          <>{!isBooleanData && renderErrorInfo()}</>
        )}
      </div>
      {isProducer && isEanUsed && (
        <div style={{ color: 'red', fontSize: 10, whiteSpace: 'break-spaces', lineHeight: 1.2, marginTop: 4 }}>
          EAN-kod redan existerar i Foodla
        </div>
      )}
    </td>
  );
};

export default ReviewTableBodyCell;
