import { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { KeycloakContext } from 'components/Secured';
import InputLabel from 'components/inputfields/InputLabel';
import InputTextField from 'components/inputfields/InputTextField';
import CopyEmailInviteProducer, { CopyEmailData } from './CopyEmailInviteProducer';

const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return re.test(email);
};

type FormDataType = {
  companyName: string;
  firstName: string;
  email: string;
  phone?: string;
};

const defaultFormData: FormDataType = {
  companyName: '',
  firstName: '',
  email: '',
};

const InviteProducer = () => {
  const keycloakCtx = useContext(KeycloakContext);

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [errors, setErrors] = useState<Partial<FormDataType>>({});
  const [success, setSuccess] = useState(false);
  const [openCopyEmailModal, setOpenCopyEmailModal] = useState(false);
  const [copyEmailData, setCopyEmailData] = useState<CopyEmailData | undefined>();

  const handleSubmit = async () => {
    const storeFirstName = keycloakCtx.keycloak?.tokenParsed?.given_name || '';

    const phoneText = formData.phone ? `\n\n Telefonnummer till kontakt: ${formData.phone}` : '';
    const subject = `Vi på ${storeFirstName} önskar sälja dina artiklar online med hjälp av Foodla!`;
    const body =
      `Hej ${formData.firstName},\n\n` +
      `Vi på ${storeFirstName} vill bjuda in er att gå med i Foodla och börja sälja era produkter online genom vår butik. När du som leverantör laddar upp produkterna i Foodla kan vi aktivera dessa i vår online-butik.\n\n` +
      `Foodla (som är med i kopian på detta mejl) kommer hjälpa er vidare med processen. På så sätt kan både vi och andra butiker runt om i Sverige erbjuda era produkter till våra e-handelskunder. Svara gärna på detta mail med en tid för att prata med Foodla kring detta.\n\n` +
      `Vi ser fram emot att kunna erbjuda era produkter snart!\n\n` +
      `Vänliga hälsningar,\n${storeFirstName}${phoneText}`;

    const to = formData.email;
    const cc = 'support@foodla.nu';
    const params = [`cc=${cc}`, `subject=${encodeURIComponent(subject)}`, `body=${encodeURIComponent(body)}`];
    const mailHref = `mailto:${encodeURIComponent(to)}?${params.join('&')}`;

    const windowRef = window.open('about:blank', '_blank');
    if (windowRef) {
      windowRef.focus();
      windowRef.location.href = mailHref;
    }

    setCopyEmailData({ to, cc, subject, body });
    setSuccess(true);
  };

  const checkErrors = useCallback(() => {
    const newErrors: Partial<FormDataType> = {};

    if (!formData.companyName) {
      newErrors.companyName = 'Vänligen, fyll i detta fält';
    }

    if (!formData.firstName) {
      newErrors.firstName = 'Vänligen, fyll i detta fält';
    }

    if (!formData.email) {
      newErrors.email = 'Vänligen, fyll i detta fält';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Vänligen, ange en giltig e-postadress';
    }
    return newErrors;
  }, [formData]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name || '';
    const newErrors = checkErrors();
    setErrors({ ...errors, [name]: (newErrors as any)[name] });
  };

  useEffect(() => {
    setSuccess(false);
    setErrors({});
    setFormData(defaultFormData);
    setCopyEmailData(undefined);
  }, [open]);

  return (
    <Box>
      <Box flexShrink={0}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Bjud in leverantör
        </Button>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Bjud in leverantör till Foodla</DialogTitle>

        <DialogContent>
          <div>
            <p style={{ marginTop: 0 }}>
              Fyll i uppgifterna för den leverantör du vill bjuda in och skicka. Din mailtjänst öppnas med en färdig
              mall med Foodla på CC, som du kan justera.
            </p>
            <InputLabel heading="Leverantörsnamn" />
            <InputTextField<any>
              inputName="companyName"
              value={formData.companyName}
              state={formData}
              forceRed={!!errors.companyName}
              errorText={errors.companyName}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Förnamn till kontakt" />
            <InputTextField<any>
              inputName="firstName"
              value={formData.firstName}
              state={formData}
              forceRed={!!errors.firstName}
              errorText={errors.firstName}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Email till kontakt" />
            <InputTextField<any>
              inputName="email"
              value={formData.email}
              state={formData}
              forceRed={!!errors.email}
              errorText={errors.email}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Telefon till kontakt (valfri)" />
            <InputTextField<any>
              inputName="phone"
              value={formData.phone}
              state={formData}
              setState={setFormData}
              onChange={handleChange}
            />

            {success && (
              <>
                <p style={{ color: 'green', marginBottom: 6 }}>
                  En förifylld inbjudan har öppnats i din mailtjänst. Kontrollera och skicka den!
                </p>
                <div
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  onClick={() => setOpenCopyEmailModal(true)}
                >
                  Det gick inte att öppna din e-postklient?
                </div>
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Box display="flex" gridGap={16} px={2} py={1}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Stäng
            </Button>
            <Button disabled={!isEmpty(checkErrors())} variant="contained" color="primary" onClick={handleSubmit}>
              Skicka
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {!!copyEmailData && (
        <CopyEmailInviteProducer
          open={openCopyEmailModal}
          data={copyEmailData}
          onClose={() => setOpenCopyEmailModal(false)}
        />
      )}
    </Box>
  );
};

export default InviteProducer;
