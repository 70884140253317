import {DocumentNode} from 'apollo-boost';

import {PRODUCT_TABS} from '../../../constants/domain';
import {ERemoveProductHandlerKey} from '../ProductCard/types';
import { IFoodlaCategoryOption } from 'models/category';

export enum EListViewType {
    CARD = 'CARD',
    LIST = 'LIST',
}

export interface IProductCardViewProps {
    rootCategory?: IFoodlaCategoryOption;
    list?: any[];
    producerId?: string;
    selectedProductId?: string;
    userResourceRole?: string;
    overrideOnClickCallback?: any;
    notificationsData?: any;
    currentCategory?: string;
    isExport?: boolean;
    showDescriptiveSize?: boolean;
    refetch?: any;
    summaryRefetch?: any;
    showEdit?: boolean;
    showSuperuserNotifications?: boolean;
    storeMe?: boolean;
    searchString?: string;
    removeProductHandlerKey?: ERemoveProductHandlerKey;
}

export interface IProductCardListProps extends IProductCardViewProps {
    loading?: boolean;
    category?: string;
    productsQuery: DocumentNode;
    queryVariables?: any;
    summaryData: any;
    queryListDataExtractor?: any;
    currentTab?: PRODUCT_TABS;
    showSearch?: boolean;
    showSorting?: boolean;
    showPagination?: boolean;
    listViewType?: EListViewType;
    showEAN?: boolean;
    newEmptyUi?: boolean;
}
