import * as dateFns from 'date-fns';
import _ from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';

import IProduct, { IFoodlaCategory } from 'models/product';
import {
  PRODUCT_BOOLEAN_FIELD,
  PRODUCT_BOOLEAN_FIELD_VALUES,
  PRODUCT_DATE_FIELD,
  PRODUCT_ENUM_FIELD,
  PRODUCT_ENUM_FIELD_VALUES,
  PRODUCT_NUMBER_FIELD,
  PRODUCT_EAN_FIELDS,
  animalCategoriesForFeed,
  securityNotices,
  hazardStatements,
  sunProtectionFactor,
  categoryWeightloss,
  preparedForm,
  signalWords,
  recyclingType,
  eggWeightClass,
  catchArea,
  fishCatchMethod,
  fishProductionMethod,
  DESCRIPTIVE_SIZE_UNITS_MAP,
  NUTRITIONAL_UNITS_MAP,
  additionalCharacteristics,
  approvalDietFeed,
  complianceMetalsSkinContact,
  confirmGuidanceSwedishElectronics,
  complianceBiocid,
  complianceSunscreenRegulation,
  complianceBiocidalTreated,
  PRODUCT_CLASSIFICATIONS,
} from 'components/constants-ts';
import { getCountryCode, hasValue } from 'utils/helpers-ts';
import { DataType, IImportColumn, IProductExcel } from '../../types';
import { VALID_DATE_FORMATS } from '../../constants';
import { hazardSymbols } from 'components/product/ProductUpdateForm/fields/hazard-fields';
import { productMarkings } from 'components/product/ProductUpdateForm/fields/labels';
import { checkDecimal, checkRequireDimenstions } from 'components/product/ProductUpdateForm/fields/gross-dimensions';
import { capitalizeTitle } from 'components/product/ProductUpdateForm/utils';

import { checkGrossWeightError } from 'components/product/ProductUpdateForm/fields/gross-weight';
import { checkDescriptiveError } from 'components/product/ProductUpdateForm/fields/descriptive';
import { checkEANValue } from 'components/product/ProductUpdateForm';

export const parseDateString = (value: string) => {
  let validDate: Date | undefined;

  VALID_DATE_FORMATS.forEach(format => {
    if (validDate) return;

    const parsedDate = dateFns.parse(value, format, new Date());
    if (!dateFns.isValid(parsedDate)) return;

    validDate = parsedDate;
  });

  return validDate;
};

export const computeBaseItems = (dataList: IProductExcel[]) => {
  const computedDataList = _.cloneDeep(dataList);

  // base items
  for (let index = 0; index < computedDataList.length; index++) {
    const mappedData = computedDataList[index];
    const {
      transportEANForStorePack,
      consumer_size_to_order_size_ratio,
      transportQtyOfNextLowerItem,
      palletLayerQtyOfNextLowerItem,
      palletQtyOfNextLowerItem,
    } = mappedData;

    // Transport Pack/Qty of base item
    if (transportEANForStorePack && consumer_size_to_order_size_ratio && transportQtyOfNextLowerItem) {
      mappedData.transportQtyOfBaseItem = consumer_size_to_order_size_ratio * transportQtyOfNextLowerItem;
    }

    // Pall/Pallet Layer/Qty of base item
    if (transportEANForStorePack) {
      if (mappedData.transportQtyOfBaseItem && palletLayerQtyOfNextLowerItem) {
        mappedData.palletLayerQtyOfBaseItem = mappedData.transportQtyOfBaseItem * palletLayerQtyOfNextLowerItem;
      }
    } else {
      if (consumer_size_to_order_size_ratio && palletLayerQtyOfNextLowerItem) {
        mappedData.palletLayerQtyOfBaseItem = consumer_size_to_order_size_ratio * palletLayerQtyOfNextLowerItem;
      }
    }

    // Pall/Pallet /Qty of base item
    if (mappedData.palletLayerQtyOfBaseItem && palletQtyOfNextLowerItem) {
      mappedData.palletQtyOfBaseItem = mappedData.palletLayerQtyOfBaseItem * palletQtyOfNextLowerItem;
    }
  }

  return computedDataList;
};

export const correctCorrespondingValues = (dataList: IProductExcel[]) => {
  const mappedDataList = _.cloneDeep(dataList);

  for (let index = 0; index < mappedDataList.length; index++) {
    const mappedData = mappedDataList[index];

    // Questions
    if (mappedData.regulatedSubstance) {
      (mappedData.reachSubstancesAnnex19072006 as any) = 'true';
    }
    if (mappedData.informationHazardousSubstances) {
      (mappedData.hazardousSubstancesOver1 as any) = 'true';
    }
    if (mappedData.explanationNoUDI) {
      (mappedData.checkUniqueIdentification as any) = 'false';
    }
    if (mappedData.CMRSubstances) {
      (mappedData.cmrSubstance as any) = 'true';
    }
  }

  return mappedDataList;
};

export const getCellValue = (column: IImportColumn, data: IProductExcel) => {
  const { field } = column;
  if (column.field === 'foodlaCategory') {
    return data.foodlaCategory?.name;
  }
  if (column.dataType === 'date') {
    const value = data[field] as Date;
    if (_.isNil(value)) return null;
    return value;
  }
  if (!_.isNil(data[field])) return data[field] as string | number;
  return null;
};

export const getEnumValue = (column: IImportColumn, data: IProductExcel) => {
  const mapEnumValue = (
    enumDataList: { value: string; displayName: string }[],
    dataType: 'array' | 'string',
    options?: {
      mapValue?: (data: any) => string;
      mapReturnData?: (data: any) => any;
    }
  ) => {
    const { mapValue, mapReturnData } = options || {};

    if (_.isNil(data[column.field])) return;
    const value = mapValue ? mapValue(data[column.field]) : data[column.field];
    if (_.isNil(value)) return;

    const validEnumList: string[] = [];
    let checkingEnumValue = String(value).toLowerCase() as unknown as string;

    enumDataList.forEach(enumData => {
      if (checkingEnumValue.includes(enumData.displayName.toLowerCase())) {
        checkingEnumValue.replace(enumData.displayName.toLowerCase(), '');
        validEnumList.push(enumData.value);
      } else if (checkingEnumValue.includes(enumData.value.toLowerCase())) {
        checkingEnumValue.replace(enumData.value.toLowerCase(), '');
        validEnumList.push(enumData.value);
      }
    });

    if (dataType === 'array') {
      return mapReturnData ? mapReturnData(validEnumList) : validEnumList;
    } else if (dataType === 'string') {
      return mapReturnData ? mapReturnData(validEnumList[0]) : validEnumList[0];
    }
  };

  if (column.field === 'descriptive_size_unit') {
    const descriptiveSizeUnitsEnumList = DESCRIPTIVE_SIZE_UNITS_MAP.map(item => ({
      value: item.enum,
      displayName: item.value,
    }));
    return mapEnumValue(descriptiveSizeUnitsEnumList, 'string');
  }
  if (column.field === 'nutritional_unit') {
    const nutritionalUnitsEnumList = NUTRITIONAL_UNITS_MAP.map(item => ({
      value: item.enum,
      displayName: item.value,
    }));
    return mapEnumValue(nutritionalUnitsEnumList, 'string');
  }
  if (column.field === 'classification') {
    return PRODUCT_CLASSIFICATIONS.find(item => item.toLowerCase() === data.classification?.toLowerCase());
  }

  if (column.field === 'securityNotices') {
    const securityNoticesEnumList = securityNotices
      .reduce(
        (all, { listData }) => [
          ...all,
          ...listData.map(({ value }) => ({ value, displayName: value.replace('_', ' + ') })),
        ],
        [] as { value: string; displayName: string }[]
      )
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(securityNoticesEnumList, 'array');
  }
  if (column.field === 'hazardStatements') {
    const hazardStatementsEnumList = hazardStatements
      .reduce(
        (all, { listData }) => [
          ...all,
          ...listData.map(({ value }) => ({ value, displayName: value.replace('_', ' + ') })),
        ],
        [] as { value: string; displayName: string }[]
      )
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(hazardStatementsEnumList, 'array');
  }
  if (column.field === 'animalCategoriesForFeed') {
    return mapEnumValue(animalCategoriesForFeed, 'array');
  }
  if (column.field === 'labels') {
    const labelsEnumList = productMarkings.reduce(
      (all, { listData }) => [...all, ...listData],
      [] as { value: string; displayName: string }[]
    );
    return mapEnumValue(labelsEnumList, 'array', {
      mapReturnData: (list: string[]) => list.map(val => ({ field: val, state: true })),
    });
  }
  if (column.field === 'eggWeightClass') {
    const eggWeightClassEnumList = eggWeightClass
      .map(({ value }) => ({
        value,
        displayName: value.replace('_', '/'),
      }))
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(eggWeightClassEnumList, 'string');
  }
  if (column.field === 'hazardSymbols') return mapEnumValue(hazardSymbols, 'array');
  if (column.field === 'sunProtectionFactor') return mapEnumValue(sunProtectionFactor, 'string');
  if (column.field === 'categoryWeightloss') return mapEnumValue(categoryWeightloss, 'string');
  if (column.field === 'preparedForm') return mapEnumValue(preparedForm, 'string');
  if (column.field === 'signalWords') return mapEnumValue(signalWords, 'string');
  if (column.field === 'recyclingType') return mapEnumValue(recyclingType, 'string');
  if (column.field === 'catchArea') return mapEnumValue(catchArea, 'string');
  if (column.field === 'fishCatchMethod') return mapEnumValue(fishCatchMethod, 'string');
  if (column.field === 'fishProductionMethod') return mapEnumValue(fishProductionMethod, 'string');
  if (column.field === 'additionalCharacteristics') return mapEnumValue(additionalCharacteristics, 'string');
  if (column.field === 'approvalDietFeed') return mapEnumValue(approvalDietFeed, 'string');
  if (column.field === 'complianceMetalsSkinContact') return mapEnumValue(complianceMetalsSkinContact, 'string');
  if (column.field === 'confirmGuidanceSwedishElectronics')
    return mapEnumValue(confirmGuidanceSwedishElectronics, 'string');
  if (column.field === 'complianceBiocid') return mapEnumValue(complianceBiocid, 'string');
  if (column.field === 'complianceBiocidalTreated') return mapEnumValue(complianceBiocidalTreated, 'string');
  if (column.field === 'complianceSunscreenRegulation') return mapEnumValue(complianceSunscreenRegulation, 'string');

  const { field, isEnumType } = column;
  const checkingValue = getCellValue(column, data);
  if (!isEnumType || _.isNil(checkingValue)) return checkingValue;

  const allowedValues = PRODUCT_ENUM_FIELD_VALUES[field]?.find(
    item => _.toString(item.value) === _.toString(checkingValue)
  );
  if (!allowedValues) return undefined;
  if (allowedValues) return allowedValues.convertTo || allowedValues.value;
  return null;
};

export const convertStringToBoolean = (valueParam: string, field: keyof IProduct) => {
  if (_.isNil(valueParam)) return null;

  const value = _.toString(valueParam).trim().toLowerCase();

  if (field) {
    const booleanOption = PRODUCT_BOOLEAN_FIELD_VALUES[field]?.find(item => item.valueList.includes(value));
    if (booleanOption) return booleanOption.convertTo;
  }

  const booleanOption = PRODUCT_BOOLEAN_FIELD_VALUES.all.find(item => item.valueList.includes(value));
  if (booleanOption) return booleanOption.convertTo;

  return null;
};

export const convertBooleanValue = (column: IImportColumn, data: IProductExcel) => {
  const { field, dataType } = column;
  const checkingValue = getCellValue(column, data);
  if (dataType !== 'boolean' || checkingValue === undefined) return checkingValue;
  return convertStringToBoolean(String(checkingValue), field);
};

export const isValidEANValue = ({ field }: Pick<IImportColumn, 'field'>, data?: IProduct) => {
  let result = false;

  if (!data || !field) return result;

  const ean = data[field as keyof IProduct];

  const eanStr = _.toString(ean).trim();

  const storePackEanField = [
    'storePackEANForStorePack',
    'transportEANForStorePack',
    'palletLayerEANForStorePack',
    'palletEANForStorePack',
  ];

  if (!/^\d+$/.test(eanStr)) result = false;
  else if (field === 'EAN') result = [8, 13].includes(eanStr.length);
  else if (field && storePackEanField.includes(field)) result = eanStr.length === 14;
  else result = [8, 12, 13, 14, 18].includes(eanStr.length);

  if (result) return result;

  // only when [palletOrderableUnit] or [palletDespatchUnit] are "TRUE".
  if (field === 'palletEANForStorePack') {
    const palletOrderableUnitValue = String(data['palletOrderableUnit']) || '';
    const palletOrderableUnit = convertStringToBoolean(palletOrderableUnitValue, 'palletOrderableUnit');

    const palletDespatchUnitValue = String(data['palletDespatchUnit']) || '';
    const palletDespatchUnit = convertStringToBoolean(palletDespatchUnitValue, 'palletDespatchUnit');

    return result || (!palletOrderableUnit && !palletDespatchUnit);
  }

  // only when [transportOrderableUnit] or [transportDespatchUnit] are "TRUE".
  if (field === 'transportEANForStorePack') {
    const transportOrderableUnitValue = String(data['transportOrderableUnit']) || '';
    const transportOrderableUnit = convertStringToBoolean(transportOrderableUnitValue, 'transportOrderableUnit');

    const transportDespatchUnitValue = String(data['transportDespatchUnit']) || '';
    const transportDespatchUnit = convertStringToBoolean(transportDespatchUnitValue, 'transportDespatchUnit');

    return result || (!transportOrderableUnit && !transportDespatchUnit);
  }

  // only when [storePackOrderableUnit] or [storePackDespatchUnit] are "TRUE".
  if (field === 'storePackEANForStorePack') {
    const storePackOrderableUnitValue = String(data['storePackOrderableUnit']) || '';
    const storePackOrderableUnit = convertStringToBoolean(storePackOrderableUnitValue, 'storePackOrderableUnit');

    const storePackDespatchUnitValue = String(data['storePackDespatchUnit']) || '';
    const storePackDespatchUnit = convertStringToBoolean(storePackDespatchUnitValue, 'storePackDespatchUnit');

    return result || (!storePackOrderableUnit && !storePackDespatchUnit);
  }

  return result;
};

export const isWrongTypeCell = (column: IImportColumn, data: IProductExcel) => {
  const { dataType, isEnumType } = column;

  const checkingValue = getCellValue(column, data);

  if (_.isNil(checkingValue)) return false;
  if (PRODUCT_EAN_FIELDS.includes(column.field)) {
    return !isValidEANValue({ field: column.field }, { ...data, [column.field]: checkingValue as string });
  }
  if (isEnumType) {
    return _.isNil(getEnumValue(column, data));
  }
  if (dataType === 'date') {
    return !checkingValue || !dateFns.isDate(checkingValue as number);
  }
  if (dataType === 'number') {
    const regex = /^([-+]?\d+(?:[,.]\d+)?)$/gimu;
    const valueToString = _.toString(checkingValue).trim();
    return !regex.test(valueToString);
  }
  if (dataType === 'boolean') {
    return _.isNil(convertBooleanValue(column, data));
  }
  return false;
};

export const isEmptyCell = (column: IImportColumn, data: IProductExcel, options?: { byTitle?: boolean }) => {
  if (column.field === 'foodlaCategory') {
    return _.isNil(data.foodlaCategory);
  }
  const value = options?.byTitle ? data._excelData?.[column.title as keyof IProduct] : getCellValue(column, data);
  return _.isNil(value) || value === '';
};

export const isInvalidValueCell = (column: IImportColumn, data: IProductExcel) => {
  const value = getCellValue(column, data);

  if (['gross_weight_num', 'gross_weight', 'gross_height', 'gross_width', 'gross_depth'].includes(column.field)) {
    return checkDecimal(value as string | number);
  }

  if (column.field === 'country_of_manufacturing_string') {
    return !getCountryCode(String(value || ''));
  }
  // not check when value is [null] or [undefined] or [empty]
  // -> this case should be check by [isEmptyCell]
  if (!_.isNil(value) && !!String(value) && column.isEnumType) {
    const enumValue = getEnumValue(column, data);
    return _.isNil(enumValue);
  }
  return false;
};

export const isMandatoryCell = (column: IImportColumn, data: IProductExcel) => {
  if (column.field === 'regulatedSubstance') {
    return convertStringToBoolean(
      data.reachSubstancesAnnex19072006 as unknown as string,
      'reachSubstancesAnnex19072006'
    );
  }
  if (column.field === 'informationHazardousSubstances') {
    return convertStringToBoolean(data.hazardousSubstancesOver1 as unknown as string, 'hazardousSubstancesOver1');
  }
  if (column.field === 'explanationNoUDI') {
    return !convertStringToBoolean(data.checkUniqueIdentification as unknown as string, 'checkUniqueIdentification');
  }
  if (column.field === 'CMRSubstances') {
    return convertStringToBoolean(data.cmrSubstance as unknown as string, 'cmrSubstance');
  }

  // Nutritional
  if (['fett', 'mattatFett', 'kolhydrat', 'sockerarter', 'protein', 'salt'].includes(column.field)) {
    const isHasKjValue = !_.isNil(data.energi_kj) && !!String(data.energi_kj).trim();
    const isHasKcalValue = !_.isNil(data.energi_kcal) && !!String(data.energi_kcal).trim();
    return isHasKjValue || isHasKcalValue;
  }

  // Signal Words
  if (['hazardSymbols', 'hazardStatements', 'securityNotices'].includes(column.field)) {
    return ['fara', 'varning'].includes(String(data.signalWords || '').toLowerCase());
  }

  return column.isMandatory;
};

export const isEanExist = (column: IImportColumn, data: IProductExcel, username: string) => {
  if (column.field !== 'EAN') return false;
  if (!data._savedDataForCheckEAN?.producer?.username) return false;
  return data._savedDataForCheckEAN.producer.username !== username && data._savedDataForCheckEAN.EAN === data.EAN;
};

export const isErrorCell = (column: IImportColumn, data: IProductExcel) => {
  let result =
    isWrongTypeCell(column, data) ||
    isInvalidValueCell(column, data) ||
    (isMandatoryCell(column, data) && isEmptyCell(column, data));

  if (!result) return false;

  if (PRODUCT_EAN_FIELDS.includes(column.field)) {
    return !isValidEANValue(column, data);
  }

  return true;
};

export const getErrorCell = (column: IImportColumn, data: IProductExcel) => {
  if (!isErrorCell(column, data) || column.field === 'image_src') return '';
  const isEmpty = isEmptyCell(column, data);

  if (isEmpty) return 'Missing value';

  if (['gross_weight_num', 'gross_weight', 'gross_height', 'gross_width', 'gross_depth'].includes(column.field)) {
    const value = getCellValue(column, data);
    if (checkDecimal(value as string | number)) {
      return 'Use only whole numbers';
    }
  }

  if (column.field === 'basicDataTaxClassification') {
    return 'Value must be 6, 12, or 25';
  }

  return 'Invalid value';
};

export const getErrorRowCells = (columnList: IImportColumn[], data: IProductExcel) => {
  const errorList: { message: string; column: IImportColumn; data: IProductExcel }[] = [];

  columnList.forEach(column => {
    const errorMessage = getErrorCell(column, data);
    if (errorMessage) {
      errorList.push({ message: errorMessage, column, data });
    }
  });

  return errorList;
};

export const checkEnumType = (field: keyof IProduct): boolean => {
  return PRODUCT_ENUM_FIELD.includes(field);
};

export const getDataType = (field: keyof IProduct): DataType => {
  if (PRODUCT_DATE_FIELD.includes(field)) return 'date';
  if (PRODUCT_NUMBER_FIELD.includes(field)) return 'number';
  if (PRODUCT_BOOLEAN_FIELD.includes(field)) return 'boolean';
  return 'string';
};

export const checkSameWithSavedBooleanValue = (column: IImportColumn, data: IProductExcel) => {
  const { _excelData, _savedData, _revertedSavedData } = data;

  if (!_savedData || !_revertedSavedData) return false;

  const oldValue = _savedData[column.field];
  const newValue = _excelData[column.title];

  return convertStringToBoolean(String(newValue), column.field) === oldValue;
};

export const checkSameWithSavedValue = (column: IImportColumn, data: IProductExcel) => {
  const { _excelData, _savedData, _revertedSavedData } = data;

  if (!_savedData || !_revertedSavedData) return false;

  const oldValue = _savedData[column.field];
  const newValue = _excelData[column.title];

  // special field
  if (column.field === 'foodlaCategory') {
    return (oldValue as IFoodlaCategory)?.name === newValue;
  }
  if (column.field === 'title') {
    return capitalizeTitle(_.toString(newValue)) === capitalizeTitle(_.toString(oldValue));
  }
  if (column.field === 'search_terms') {
    return oldValue === newValue;
  }
  if (column.field === 'article') {
    return [_savedData.article, _savedData.purchasingDataSupplierArticleNo].includes(_.toString(newValue));
  }
  if (column.field === 'country_of_manufacturing_string') {
    return getCountryCode(newValue as string) === _savedData.country_of_manufacturing;
  }

  if (_.isNil(oldValue)) return false;

  // Date
  if (column.dataType === 'date') {
    let valueTimestamp = Infinity;
    if (dateFns.isDate(newValue)) {
      valueTimestamp = utcToZonedTime(newValue as Date, 'Europe/Stockholm').getTime();
    }
    return valueTimestamp === oldValue;
  }

  // Enum
  if (column.isEnumType) {
    return getEnumValue(column, { ...data, [column.field]: newValue }) === oldValue;
  }

  // Boolean
  if (column.dataType === 'boolean') {
    return checkSameWithSavedBooleanValue(column, data);
  }

  // Number
  if (column.dataType === 'number') {
    const regex = /^([-+]?\d+(?:[,.]\d+)?)$/gimu;
    const stringValue = _.toString(newValue).trim().replace(',', '.');
    const numberValue = parseFloat(stringValue);
    return stringValue.match(regex) && _.toString(numberValue) === _.toString(oldValue);
  }

  // String
  return _.toString(newValue) === _.toString(oldValue);
};

export const checkValueReviewed = (column: IImportColumn, data: IProductExcel) => {
  if (!data?._savedData) return false;
  const savedData = data._savedData;

  const isHasValue = hasValue(savedData[column.field]);
  const onlyRequireAndNeedApproveFields = [
    'title',
    'ingredient_statement',
    'short_text',
    'energi_kj',
    'energi_kcal',
    'fett',
    'mattatFett',
    'kolhydrat',
    'sockerarter',
    'protein',
    'salt',
    'trade_item_temperature_information',
    'place_of_item_activity',
    'brand',
    'brand_food',
    'search_terms',
  ] as (keyof IProduct)[];

  // check if their value are empty
  if (onlyRequireAndNeedApproveFields.includes(column.field)) {
    return isHasValue;
  }

  if (column.field === 'EAN') {
    const { eanLengthError, eanControlError, eanWrongCoopStoreError, nonUniqError } = checkEANValue(savedData, true);
    return (
      isHasValue &&
      !(
        (!!eanLengthError && !savedData.ignoreEANLengthError) ||
        !!eanControlError ||
        !!eanWrongCoopStoreError ||
        !!nonUniqError
      )
    );
  }

  // gross_weight, gross_weight_num
  if ((['gross_weight', 'gross_weight_num'] as (keyof IProduct)[]).includes(column.field)) {
    return isHasValue && !checkGrossWeightError(savedData);
  }

  // descriptive_size_amount
  if (column.field === 'descriptive_size_amount') {
    return isHasValue && !checkDescriptiveError(savedData);
  }

  // GrossDimensions
  if (column.field === 'gross_height') {
    return (
      isHasValue &&
      !checkDecimal(savedData.gross_height) &&
      !(!!checkRequireDimenstions(savedData) && !String(savedData.gross_height))
    );
  }
  if (column.field === 'gross_width') {
    return (
      isHasValue &&
      !checkDecimal(savedData.gross_width) &&
      !(!!checkRequireDimenstions(savedData) && !String(savedData.gross_width))
    );
  }
  if (column.field === 'gross_depth') {
    return (
      isHasValue &&
      !checkDecimal(savedData.gross_depth) &&
      !(!!checkRequireDimenstions(savedData) && !String(savedData.gross_depth))
    );
  }

  return false;
};
