import { FC, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { path } from 'ramda';
import axios from 'axios';
import { useMutation } from '@apollo/react-hooks';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { restEndpoints } from 'constants/domain';
import { compressImage, convertTiffToPng } from 'components/fileuploader/ImageUploader2';
import { KeycloakContext } from 'components/Secured';
import InputLabel from 'components/inputfields/InputLabel';
import { mapChatGptDataToFill, removeNilProperty } from 'components/vision/tabs/chatGPT';
import IProduct, { IFoodlaCategory, IRecognizeImage } from 'models/product';
import { S3_SIGNED_REQUEST_MUTATION } from 'graphql/mutations';
import { encodeLastPathUrl } from 'utils/imgix';
import { getImageWithSizeSuffix, IMAGE_SIZES } from 'utils/images2';
import { isBlank } from 'utils/helpers-ts';
import productInfoIcon from 'assets/images/product-info-icon.png';
import useViewport from 'hooks/useViewports';
import { isNewFood } from '../fields/category';
import { IFoodlaCategoryOption } from 'models/category';
import IProducer from 'models/producer';

export const ALLOWED_IMAGE_FILE_TYPE_LIST = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/png'];

interface FillProductByImageProps {
  rootCategory?: IFoodlaCategoryOption;
  producerData?: IProducer;
  isFood?: boolean;
  isCreatingNew?: boolean;
  isChangedBrand?: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  onChangedState: () => void;
}

const grid = 4;

const getImageStyle = (itemIndex: number): React.CSSProperties => ({
  display: 'inline-flex',
  borderRadius: 2,
  //width: 120,
  height: 120,
  boxSizing: 'border-box',
  cursor: 'pointer',
});

// const getListStyle = (isDraggingOver: boolean) => ({
//   marginTop: 20,
//   marginBottom: 20,
//   background: isDraggingOver ? 'rgba(173, 213, 255, 0.5)' : 'lightgrey',
//   borderRadius: 3,
//   display: 'flex',
//   padding: grid,
//   overflow: 'auto',
// });

// interface FileWithPreview extends File {
//   path?: string;
//   preview?: string;
// }

const getItemStyle = (
): React.CSSProperties => ({
  maxHeight: 400,
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  borderRadius: 2,
  display: 'flex',
  border: 'none',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: "white",
});

type TSignedRequest = {
  signedRequest: string;
  url: string;
  pictureUrl: string;
  picturePath: string;
  fileName: string;
};

type TSignedRequestResponse = {
  getS3SignRequest: {
    requests: TSignedRequest[];
  };
};

const API_TIMEOUT = 60 * 1000;

interface IRecognizeImageData extends Partial<IProduct> {
  recognizeData?: boolean;
}

interface IRecognizeCategoryData {
  categoryID?: string;
  categoryName?: string;
  categoryTree?: string;
  text?: string;
}

const recognizeImage = async (token: string, imageUrl?: string) => {
  try {
    if (isBlank(token)) {
      return;
    }
    if (isBlank(imageUrl)) {
      return;
    }
  
    const formData = new FormData();
    formData.append('imageUrl', imageUrl!);
  
    const { data } = await axios.post<{ data: IRecognizeImageData }>(restEndpoints.aiImage, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = data?.data;
    return result;
  } catch (error: any) {
    console.error('recognizeImage error:', error.message);
    return;
  }
};

const recognizeCategory = async (token: string, imageUrl?: string) => {
  try {
    if (isBlank(token)) {
      return;
    }
    if (isBlank(imageUrl)) {
      return;
    }
  
    const formData = new FormData();
    formData.append('imageUrl', imageUrl!);
  
    const { data } = await axios.post<{ data: IRecognizeCategoryData }>(restEndpoints.aiCategory, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = data?.data;
    return result;
  } catch (error: any) {
    console.error('recognizeImage error:', error.message);
    return;
  }
};

const FillProductByImage: FC<FillProductByImageProps> = ({
  rootCategory,
  producerData,
  isFood,
  isCreatingNew,
  isChangedBrand,
  state,
  setState,
  onChangedState,
}) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx) as string;
  const { smUp } = useViewport();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modifiedCount, setModifiedCount] = useState(0);
  // const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [images, setImages] = useState<IRecognizeImage[]>(state.recognizeImages || []);
  // const [imageUrl, setImageUrl] = useState<string|undefined>(undefined);

  // const [categoryData, setCategoryData] = useState<any>({});

  // useEffect(() => {
  //   const hasCategory = !isBlank(state?.foodlaCategory?.id);
  //   if (imageUrl && !hasCategory) {
  //     const formData = new FormData();
  //     formData.append('imageUrl', imageUrl);
  //     axios.post<{ data: Record<string, any> }>(restEndpoints.aiCategory, formData, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     }).then(({ data }) => {
  //       // console.log('!!!categoryData', data);
  //       const categoryData = data?.data;
  //       setCategoryData(categoryData);
  //     });
  //   }
  // }, [imageUrl]);

  // useEffect(() => {
  //   if (categoryData) {
  //     if (!isBlank(categoryData?.categoryID) && !isBlank(categoryData?.categoryName)) {
  //       const category: IFoodlaCategory = {
  //         id: categoryData?.categoryID,
  //         name: categoryData?.categoryName,
  //       };
  //       setState({ ...state, foodlaCategory: category });
  //     }
  //     console.log('!!!categoryData', categoryData);
  //   }
  // }, [categoryData]);
  
  const [s3SignedRequest] = useMutation<TSignedRequestResponse>(S3_SIGNED_REQUEST_MUTATION, { refetchQueries: [] });
  const producerName = state?.producerUsername || state?.producerName || '';
  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_IMAGE_FILE_TYPE_LIST.join(', '),
    multiple: false,
    disabled: loading,
    onDrop: async (acceptedFiles: File[]) => {
      const rawFile = acceptedFiles[0];
      if (!rawFile) return;

      setIsError(false);
      setLoading(true);
      setModifiedCount(0);

      let file = await convertTiffToPng(rawFile);
      if (!file) return;

      file = await compressImage(file, 10);
      if (!file) return;

      console.log('!!!file', file);
      const input = {
        ean: state.EAN || '',
        producerName: producerName,
        files: [{ fileName: file.name, fileType: file.type }],
        renameFiles: true,
      };
      console.log('input', input);
      console.log('state', state);
      const { data, errors } = await s3SignedRequest({
        variables: {
          input,
        },
      });
      let newImageUrl = '';
      if (!errors) {
        console.log('!!!');
        console.log(data);
        const signedRequest = data?.getS3SignRequest?.requests?.[0];
        console.log('!!!signedRequest', signedRequest);

        if (signedRequest) {
          const signedResult = await axios.put(signedRequest.signedRequest, file, {
            timeout: API_TIMEOUT,
            headers: { 'Content-Type': file.type },
          });
          console.log('!!!signedResult', signedResult);
          const url = encodeLastPathUrl(signedRequest.pictureUrl);
          console.log('!!!url', url);
          // setImageUrl(url);
          // uploadedImageData.url = encodeLastPathUrl(signedRequest.pictureUrl);
          if (url) {
            const oldImages = images || [];
            const image: IRecognizeImage = {
              url,
              name: file.name,
            };
            oldImages.push(image);
            setImages(oldImages);
            newImageUrl = url;
          }
        }
      } else {
        console.log('!!!');
        console.error(errors);
      }


      // if (imageUrl) {
      //   const formData = new FormData();
      //   formData.append('imageUrl', imageUrl);
      //   const { data: categoryData } = await axios.post<{ data: Record<string, any> }>(restEndpoints.aiCategory, formData, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   });
      //   console.log('!!!categoryData', categoryData);
      // }

      // const chatGptData = await handleSendImageToChatGpt(token, newImageUrl);

      let category: IFoodlaCategory|undefined;
      let isFoodAfterUpdate = isFood;
      if (isBlank(state?.foodlaCategory?.id)) {
        const recognizeCategoryData = await recognizeCategory(token, newImageUrl);
        console.log('chatGPT - category', recognizeCategoryData);
        if (!isBlank(recognizeCategoryData?.categoryID) && !isBlank(recognizeCategoryData?.categoryName)) {
          category = {
            id: recognizeCategoryData!.categoryID!,
            name: recognizeCategoryData!.categoryName!,
          };
          isFoodAfterUpdate = isNewFood(category, rootCategory);
          setState({ ...state, foodlaCategory: {...category} });
        }
      }

      const recognizeImageData = await recognizeImage(token, newImageUrl);
      console.log('chatGPT', recognizeImageData);

      if (recognizeImageData?.recognizeData) {
        let { data: updatingState, modifiedCount: count } = mapChatGptDataToFill(state, recognizeImageData, {
          isFood: isFoodAfterUpdate,
          isChangedBrand,
          producer: producerData,
          isCreatingNew,
        });
        console.log('updatingState1', updatingState);
        console.log('updatingState1 - isFood', isFoodAfterUpdate);
        console.log('updatingState1 - isChangedBrand', isChangedBrand);
        updatingState = removeNilProperty(updatingState);
        console.log('updatingState2', updatingState);
        if (count) {
          onChangedState();
        }
        if (category) {
          updatingState.foodlaCategory = category;
        }
        setState({ ...state, ...updatingState });
        setIsError(false);
        setModifiedCount(count);
      } else {
        setIsError(true);
        setModifiedCount(0);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    return () => {
      if (setState) {
        setState({ ...state, recognizeImages: images });
      }
    };
  }, [images]);
  return (
    <Box my={2}>
      <InputLabel heading="Bild på produktinformation" />
      <p>
        Ladda upp en bild med produktinformation. t. ex. baksidan av produkt, en skärmdump eller etikett. Foodla autofyller fält, kategoriserar och ger förslag på en bra produktbeskrivning! Tänk på att ladda upp en så skarp bild som möjligt, den får gärna vara tagen i ett så ljust rum som möjligt.
      </p>
      <div
        {...getRootProps({
          className: clsx('dropzone-custom fill-product-by-image-bg', !!images.length && 'has-item'),
          style: { minHeight: !images.length ? '160px' : undefined },
        })}
      >
        <input {...getInputProps()} />
        <div className={clsx('dropzone-text', loading && 'dropzone-loading')}>
          {loading ? (
            <span className='loader'>
              <CircularProgress size={30} />
              <span style={{ marginTop: 8 }}>
                Foodla läser nu av all information vi kan se på bilden
              </span>
            </span>
          ) : (
            <>
              <div className='dropzone-icon'>
                <img src={productInfoIcon} alt='' />
              </div>
              {!images?.length && (
                <div>
                  {smUp
                    ? 'Dra och släpp en bild här eller klicka för att välja en från din dator (Stödda format: JPG, JPEG, PNG, TIF).'
                    : 'Klicka här för att öppna kameran eller välja en bild från ditt bibliotek'}
                </div>
              )}
            </>
          )}
        </div>

        {!!images.length && (
          <div className='list-items' style={{ overflow: 'auto' }} onClick={(event) => event.stopPropagation()}>
            {images.map((image, index) => {
              return (
                <div
                  style={getItemStyle()}
                >
                    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <img
                      src={image.url ? getImageWithSizeSuffix(image.url, IMAGE_SIZES.SMALL) : ''}
                      style={getImageStyle(index)}
                      alt="Not available"
                      onClick={() => window.open(image.url, '_blank')}
                    />
                    </div>
                  <b>{image.name}</b>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {!!modifiedCount && (
        <Box id="ProductForm-FillProductByImage-Message-Success" mt={1} color="green" fontWeight={700}>
          Foodla har hittat produktinformation på bilden du laddade upp och vi har hjälpt dig att fylla i informationen från bilden i fälten nedan!
        </Box>
      )}

      {isError && (
        <Box id="ProductForm-FillProductByImage-Message-Error" mt={1} color="orangered" fontWeight={700}>
          Det verkar inte som om att vi kunde hitta produktinformationen på bilden du laddade upp. Testa gärna ladda upp
          en bild där produktinformationen är synlig så gör vi ett försök till!
        </Box>
      )}
    </Box>
  );
};

export default FillProductByImage;
