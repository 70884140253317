import { FC, useContext, useState } from 'react';
import axios from 'axios';
import { path } from 'ramda';

import { restEndpoints } from 'constants/domain';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useViewport from 'hooks/useViewports';
import { KeycloakContext } from 'components/Secured';
import { ResponseSnackBar } from 'components/snackbar/SnackBar';

interface InterestWhereProductShownOnlineProps {}

const InterestWhereProductShownOnline: FC<InterestWhereProductShownOnlineProps> = () => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx) as string;

  const { mdUp } = useViewport();

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [snakeMessage, setSnakeMessage] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    setSending(true);
    try {
      const formData = new FormData();
      formData.append('message', message);

      await axios.post<{ data: string }>(restEndpoints.surveyKnowProductOnline, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOpen(false);
      setSnakeMessage('Tack! Vi har registrerat ditt intresse och återkommer');
    } catch (error: any) {
    } finally {
      setSending(false);
    }
  };

  return (
    <Box
      display="flex"
      gridGap={16}
      flexDirection={mdUp ? 'row' : 'column'}
      alignItems={mdUp ? 'center' : 'start'}
      px={3}
      py={2}
    >
      <Box flexGrow={1}>
        <Box fontWeight={700}>Vill du veta var dina varor finns online?</Box>
        <Box color="gray">
          Vi utvärderar att utveckla en funktion som visar var dina produkter säljs online. Klicka för att läsa mer!
        </Box>
      </Box>

      <Box flexShrink={0}>
        <Button
          id="ProducerProducts-SurveyShowOnline-ReadMoreButton"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Läs mer
        </Button>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Intresseanmälan: Få insikt om var dina produkter säljs</DialogTitle>

        <DialogContent>
          <div>
            <p style={{ marginTop: 0 }}>
              Vi planerar att utveckla en funktion som visar var dina produkter säljs online. Detta skulle ge dig
              överblick över din marknadsräckvidd och hjälpa dig upptäcka nya försäljningsmöjligheter.
            </p>
            <p>
              Genom att anmäla ditt intresse hjälper du oss utvärdera behovet av denna funktion. Dela gärna med dig av
              dina tankar eller önskemål nedan.
            </p>
            <TextField
              fullWidth
              multiline
              size="small"
              variant="outlined"
              minRows={4}
              placeholder="Dela tankar och önskemål (valfritt)"
              value={message}
              onChange={event => setMessage(event.target.value)}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Box display="flex" gridGap={16} px={2} py={1}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Avbryt
            </Button>
            <Button
              id="ProducerProducts-SurveyShowOnline-SubmitButton"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {sending ? <CircularProgress color="inherit" size={16} style={{ margin: 4 }} /> : 'Anmäl intresse'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {!!snakeMessage && (
        <ResponseSnackBar
          success
          message={snakeMessage}
          duration={3000}
          title=""
          onClosedCallback={() => setSnakeMessage('')}
        />
      )}
    </Box>
  );
};

export default InterestWhereProductShownOnline;
